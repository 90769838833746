import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';


import InputBase from '@material-ui/core/InputBase';

import axios from 'axios';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import data from '../config/data';
import { AssignmentLateTwoTone, DiscFullRounded, Close as CloseIcon } from '@material-ui/icons';
import edusim from '../config/edusim';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  containedPaper:{
    margin: theme.spacing(0),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  }
}));

export default function StudentGradeSummaryView(props) {
  const [loading, setLoading] = React.useState(false);
  const [assignments, setAssignments] = React.useState([]);
  const [categoryTemplate, setCategoryTemplate] = React.useState({});
  const [assignmentAverages, setAssignmentAverages] = React.useState({});
  const [categoryTotalPoints, setCategoryTotalPoints] = React.useState({});
  const [earnedPointsInCategory, setEarnedPointsInCategory] = React.useState({});
  
  const isMounted = React.useRef(null);

  const propsUser = props.user;
  const propsUpdateUser = props.updateUserInformation;
  const propsTermId = props.termid;
  const propsStudent = props.student;
  const propsSectionId = props.sectionid;
  const propsSection = props.section;

  const classes = useStyles();

  function addChatTemp(){
    console.log(propsStudent, propsSectionId);
    axios.post(edusim.api_base_uri+"/api/instructors/addStudentChat",
    {_studentid: propsStudent._id, _sectionid:propsSectionId},
    {
      headers: {
        'x-access-token': propsUser.token
      }}).then((res)=>{
        console.log(res.data)
        //setMessages(res.data);
      }); 
  }

  React.useEffect(()=>{

    isMounted.current = true;

    const loadAssignments = () => {
      setAssignments(propsSection.assignments);
      setCategoryTemplate(propsSection.categoryTemplate);
      propsSection.assignments.map((assignment, key)=>{
        if(assignment.earned_points && assignment.points){
          const studentPoints = assignment.earned_points[propsStudent._id]?assignment.earned_points[propsStudent._id]:null;
          if(studentPoints){
            let averageAssignmentScore = (studentPoints/assignment.points)*100;
            setAssignmentAverages((currentValue)=> ({...currentValue, [assignment._id] : averageAssignmentScore}));
            if(assignment._categoryid){
              setCategoryTotalPoints((currentValue)=>(
                {...currentValue, 
                  [assignment._categoryid]: 
                    currentValue[assignment._categoryid]?
                    currentValue[assignment._categoryid]+(assignment.points):
                    assignment.points
                }));
              setEarnedPointsInCategory((currentValue)=>(
                {...currentValue, 
                  [assignment._categoryid]: 
                    currentValue[assignment._categoryid]?
                    currentValue[assignment._categoryid]+studentPoints:
                    studentPoints
                }));
            }
          }
        }

      });
    };

    //load existing section
    if(propsStudent&&propsSectionId&&propsTermId)
      loadAssignments();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [propsStudent, propsSectionId, propsTermId, propsUpdateUser, propsUser]);

  const assignmentCountInCategory = (_categoryid) =>{
    return assignments.filter(e=>e._categoryid === _categoryid).length;
  }

  const assignmentsInCategory = (_categoryid) => {
    return assignments.filter(e=>e._categoryid === _categoryid);
  }

  const categoryAverage = (_categoryid) => {
    return categoryTotalPoints[_categoryid]>0?(earnedPointsInCategory[_categoryid]/categoryTotalPoints[_categoryid])*100:null;
  }

  const categoryIntervalAverage = (_categoryid, _intervalid) => {
    let assignmentsInInterval = propsSection.assignments.filter(e=>(e._intervalid==_intervalid)&&e._categoryid==_categoryid);
    let assignmentsSumInInterval = 0;
    let assignmentTotalPointsInInterval = 0; 
    assignmentsInInterval.forEach((assignment)=>{
        //old way
        //assignmentsSumInInterval += assignmentAverages[assignment._id]?assignmentAverages[assignment._id]:0;
        //new way
        assignmentsSumInInterval += assignment.earned_points[propsStudent._id]?assignment.earned_points[propsStudent._id]:0;
        assignmentTotalPointsInInterval += assignment.earned_points[propsStudent._id]?assignment.points:0;
    });

    /* return assignmentsInInterval.length>0?
    assignmentsSumInInterval/assignmentsInInterval.length:null; */
    return assignmentTotalPointsInInterval>0?
    (assignmentsSumInInterval/assignmentTotalPointsInInterval)*100:null
  }

  const categoryWeightRatio = (_categoryid) => {
      let foundIndex = categoryTemplate.categories.findIndex(e=>e._id==_categoryid);
      return foundIndex>=0?categoryTemplate.categories[foundIndex].weightRatio:null;
  }

  const assignmentAverage = (_assignmentid) => {
    return formatPercent(assignmentAverages[_assignmentid]);
  }

  const assignmentInterval = (assignment) => {
    return propsSection.intervals[propsSection.intervals.findIndex(i=>i._id===assignment._intervalid)];
  }

  const termAverage = () =>{
    let termScore = 0;
    for (const categoryid in categoryTotalPoints){
      if(categoryTotalPoints[categoryid]>0){
        let weightedScore = categoryAverage(categoryid) * categoryWeightRatio(categoryid);
        termScore += weightedScore;
      }
    }
    return formatPercent(termScore);
  }

  const intervalWeightedAverage = (_intervalid) => {
    let assignmentsInInterval = propsSection.assignments.filter(e=>(e._intervalid==_intervalid));
    let intervalCategoryWeight = {};
    intervalCategoryWeight[_intervalid] = {};
     assignmentsInInterval.forEach((assignment)=>{
      assignment.earned_points&&Object.keys(assignment.earned_points).length>0&&
       (intervalCategoryWeight[_intervalid][assignment._categoryid] = categoryWeightRatio(assignment._categoryid));
     });
     for(const categoryid in intervalCategoryWeight[_intervalid]){
        intervalCategoryWeight[_intervalid]["sum"]?
        intervalCategoryWeight[_intervalid]["sum"]+=intervalCategoryWeight[_intervalid][categoryid]
        :intervalCategoryWeight[_intervalid]["sum"]=intervalCategoryWeight[_intervalid][categoryid];
     }
     let intervalWeightedScore = 0;
     for(const categoryid in intervalCategoryWeight[_intervalid]){
      intervalWeightedScore += categoryIntervalAverage(categoryid, _intervalid) 
                              * (intervalCategoryWeight[_intervalid][categoryid]/intervalCategoryWeight[_intervalid]["sum"]);
     }
     return intervalWeightedScore;
  }

  function getPercent(ratio){
    return (Math.round(((ratio*100) + Number.EPSILON) * 100) / 100);
  }

  function formatPercent(percent){
    return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
  }

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      //color: theme.palette.common.white,
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 2
    },
    body: {
      backgroundColor: "#fff",
      minWidth: "50px",
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 1
    }
  }))(TableCell);
  
  const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

  return (

    <React.Fragment>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => props.setSelectedStudent(null)}>
          <CloseIcon />
        </IconButton>
        </Grid>
        <Grid item>
        <Typography component="h2" variant="h6" color="primary">Student Grading Summary: {propsStudent.name} </Typography>
        {/* <Button onClick={()=>addChatTemp()}>Add Student Chat</Button> */}
        </Grid>
      </Grid>
      
      <Grid container 
        direction = "row"
        justify ='flex-start'
        alignItems ="center"
        spacing={1}
        style={{marginBottom:"1em"}}>            
        <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Paper className={classes.containedPaper} style={{backgroundColor: '#fffddb'}}>
        <Typography className="summaryTitle">Term Weighted Average: <strong>{termAverage()}</strong></Typography>
        </Paper>
        </Grid>
        {categoryTemplate&&categoryTemplate.categories&&propsSection.intervals.map((interval, key)=>(
          <Grid key={key} item xs={12} sm={12} lg={6} xl={6}>
          <Paper className={classes.containedPaper} style={{backgroundColor: '#d0fffe'}}>
            <Typography className="summaryTitle">{interval.short_name} Weighted Average: <strong>{intervalWeightedAverage(interval._id)?formatPercent(intervalWeightedAverage(interval._id)):"N/A"}</strong></Typography>
          </Paper>
          </Grid>
        ))}
        
      </Grid>
      
    {categoryTemplate&&categoryTemplate.categories&&categoryTemplate.categories.map((category, key)=>(
    <Paper key={key} className={classes.paper}>
    
    <Typography variant="h6" style={{marginBottom: "1em"}}>{category.name} ({formatPercent(getPercent(category.weightRatio))} of Total Grade)</Typography>
   
    
    <Grid container 
        direction = "row"
        justify ='flex-start'
        alignItems ="center"
        spacing={1}
        style={{marginBottom:"1em"}}>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
      <Paper className={classes.containedPaper} style={{backgroundColor: '#ffe7d3'}}>
      <Typography>Category Average: <strong>{categoryAverage(category._id)?formatPercent(categoryAverage(category._id)):"N/A"}</strong></Typography>
      </Paper>
      </Grid>
    {propsSection.intervals.map((interval, key)=>(
      <Grid key={key} item xs={12} sm={12} lg={6} xl={6}>
        <Paper className={classes.containedPaper} style={{backgroundColor: '#eeeeee'}}>
        {interval.short_name} Average: <strong>{categoryIntervalAverage(category._id, interval._id)?formatPercent(categoryIntervalAverage(category._id, interval._id)):"N/A"}</strong>
        </Paper>  
      </Grid> 
    ))}
    </Grid>
    <TableContainer>
      <Table className={classes.table} aria-label="Assignments">
        <TableHead>
          <TableRow>
              <TableHeaderCell align="left">
                Name
              </TableHeaderCell>
              <TableHeaderCell align="left">
                Interval
              </TableHeaderCell>
              <TableHeaderCell align="left">
                Average Score
              </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignmentsInCategory(category._id).map((assignment, akey)=>(
            <TableRow key={akey}>
               <TableCell align="left">
                {assignment.name}
              </TableCell>
              <TableCell align="left">
                {assignmentInterval(assignment)?assignmentInterval(assignment).name + ' (' + assignmentInterval(assignment).short_name + ')':"Unassigned"}
              </TableCell>
              <TableCell align="left">
                {assignmentAverage(assignment._id)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      </Paper>
    ))}

    </React.Fragment>

   
    
  );
}
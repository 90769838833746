import React from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Tooltip,
    makeStyles,
    TextField,
    Button
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon,
    SubdirectoryArrowLeft,     
} from '@material-ui/icons';

import FloatingActionButton from './FAB';

import AssignmentGradeEntry from '../views/AssignmentGradeEntry';
import AssignmentView from '../views/AssignmentView';
import axios from 'axios';
import edusim from '../config/edusim';
import moment from 'moment';

import SubjectObjectivesView from './SubjectObjectivesView';

const SubjectProgReportView = (props) => {
    const subject = props.subject;
    
    //on edit
    const propsReport = props.selectedReport;
    
    //on add
    const selectedGi = props.selectedGi;
    const selectedYear = props.selectedYear;
    const selectedTerm = props.selectedTerm;

    const [progressReport, setProgressReport] = React.useState(propsReport?propsReport:{});
    const [loading, setLoading] = React.useState(false);
    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;
    const refreshProgressReports = props.refreshProgressReports;

    //error handling states
    const [error, setError] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [errorMessages, setErrorMessages] = React.useState({});

    const [selectedObjectives, setSelectedObjectives] = React.useState({});
    
    React.useEffect(()=>{
        let validatedReport = progressReport;
        const validateFields = () => {
          let reportValid = true;
          
          if(!validatedReport.name){
            reportValid = false;
            setErrors(errors=> ({...errors, 'name': true}));
            setErrorMessages(errorMessages => ({...errorMessages, 'name': 'Required!'}));
          }else if(validatedReport.name.length < 5){
            reportValid = false;
            setErrors(errors=> ({...errors, 'name': true}));
            setErrorMessages(errorMessages=> ({...errorMessages, 'name': 'Minimum of 5 characters is required'}));
          }else{
            cancelErrors('name');
          }
    
          if(!validatedReport.deadline||!validDate(validatedReport.deadline, true)){
            reportValid = false;
            setErrors(errors=>({...errors, 'deadline': true}));
            setErrorMessages(errorMessages=>({...errorMessages, 'deadline': 'ISO Date like 2022-12-22'}));
          }else{
            cancelErrors('deadline');
          }
    
    
         if(validatedReport.validateCallBack)
              validatedReport.validateCallBack(reportValid);
        
        }
    
        const cancelErrors = (fieldName) => {
          setErrors(errors=>({...errors, [fieldName]: false}));
          setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
        }
    
        const validDate = (date, required) => {
          //we are only interested in the first 10 charachters of this string
          if(!required && date.length===0) return true;
          let checkedDate = date.split("T")[0];
          if (moment(checkedDate?checkedDate:date, "YYYY-MM-DD", true).isValid()) {
            return true;
          } else {
            return false;
          }
        }
        
        const validTime = (time, required) =>{
          if(!required && time.length===0) return true;
          return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
        }
    
        const validPositiveInt = (num, required) =>{
          if(!required && num===null) return true;
          if(isNaN(num)) return false;
          return /^[0-9\b]+$/.test(num);
        }
    
        if(validatedReport?.validate){
          validateFields();
        }
          
    },[progressReport]);

    React.useEffect(()=>{
        setTimeout(()=>{
          if(error !== ''){
            setError('');
          }
        }, 3000);
    },[error]);

    const handleSaveReport = (e) => {
        setProgressReport(report=>({...report, validate:true, validateCallBack: (validReport)=>{
          if(!validReport){
            setError('Please fix the required information before proceeding.');
            setProgressReport(report=>({...report, validateCallBack: null}));
          }else if(getSelectedObjectivesCount()<1){
            setError('Select at least 1 objective.');
            setProgressReport(report=>({...report, validateCallBack: null}));
          } else{
            _saveReport();
          }
        }}));
    }

    const _saveReport = () => {
        let progressReportToSave = {...progressReport};
        progressReportToSave._subjectid = subject._id;
        progressReportToSave._termid = selectedTerm._id;
        progressReportToSave._gradingintervalid = selectedGi._id;
        progressReportToSave.selectedObjectives = {};
        progressReportToSave.selectedObjectives.jsonObject = JSON.stringify(selectedObjectives);
        //console.log(progressReportToSave);
        setLoading(true);
        axios.post(edusim.api_base_uri+"/api/instructors/managers/progressReports/"+subject._id, 
        {state: progressReportToSave}, {
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          setLoading(false);
          propsReport?props.setSelectedReport(null):props.setNewReportVisible(false);
          refreshProgressReports();
        }).catch(e => {
          if(e.response){
              if(e.response.status === 403){
                  propsUpdateUser({});
              }else{
                  setError("Cannot add progress report at this moment.");
              }
          }else{
              setError("Network connection might be lost");         
          }
        }).finally(()=>{
            setLoading(false);
        });
      
    }

    const handleCloseClick = (e)=>{
        propsReport?props.setSelectedReport(null):props.setNewReportVisible(false);
    }

    const handleTextChange = (e, fieldName) => {
        setProgressReport({...progressReport, [fieldName]: e.target.value.trim()});
    }

    const getSelectedObjectivesCount = () =>{
      let selCount = 0;
      const domainKeys = Object.keys(selectedObjectives);
      domainKeys.forEach((domainKey)=>{
        const clusterKeys = Object.keys(selectedObjectives[domainKey]);
        clusterKeys.forEach((clusterKey)=>{
          const standardKeys = Object.keys(selectedObjectives[domainKey][clusterKey]);
          standardKeys.forEach((standardKey)=>{
            standardKey!=="selected"&&selectedObjectives[domainKey][clusterKey][standardKey].selected&&(selCount++);
            const substandardKeys = Object.keys(selectedObjectives[domainKey][clusterKey][standardKey]);
            if(substandardKeys.length>1){
              substandardKeys.forEach((substandardKey)=>{
                substandardKey!=="selected"&&selectedObjectives[domainKey][clusterKey][standardKey][substandardKey].selected&&(selCount++);
              })
            }
          })
        });
      })
      return selCount;
    }

    return (
        <React.Fragment>       
            {(error !== '') &&
            <Alert style={{marginBottom: 10}} severity='error'>
                {error}
            </Alert>
            }
            <Typography component="h2" variant="h6" color="primary" gutterBottom>{propsReport?'Edit ':'Add '} Progress Report</Typography>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={(selectedTerm.name + ' ' + selectedYear)}
                        label="Term"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={(selectedGi.name + '(' + selectedGi.short_name + ')')}
                        label="Grading Interval"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
            </Grid>
            
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.name : ''}
                        error={errors['name']? true: false}
                        helperText={errorMessages['name']? errorMessages['name']: null}
                        onChange={(e) => handleTextChange(e, 'name')}
                        label="Report Name"
                        variant="outlined" 
                        fullWidth
                        autoFocus>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.deadline : ''}
                        error={errors['deadline']? true: false}
                        helperText={errorMessages['deadline']? errorMessages['deadline']: null}
                        onChange={(e) => handleTextChange(e, 'deadline')}
                        label="Report Deadline"
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
            </Grid>

            <Typography style={{marginTop: "30px"}} component="h2" variant="h6" color="default">Select Academic Objectives - {getSelectedObjectivesCount()} Selected</Typography>

            <SubjectObjectivesView progressSelection={true} objectives={props.objectives} prevSelectedObject={propsReport?JSON.parse(propsReport.selectedObjectives.jsonObject):null} setSelectedObjectives={setSelectedObjectives} />

            <Typography style={{marginTop: "30px"}} component="h2" variant="h6" color="default">School Wide Objectives (Included By Default)</Typography>
            <ol>
            {props.schoolObjectives.map((objective, key)=>(
                <li key={key}>{objective}</li>
            ))}
            </ol>
            <Grid container 
                direction="row"
                justify="flex-end"
                alignItems="flex-end" spacing={3}>
                <Grid item>
                    <Button
                        onClick={(e) => handleCloseClick(e)}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleSaveReport(e)}>
                        {propsReport ? "Save" : "Add"} Report
                    </Button>
                </Grid>
            </Grid>
            {(error !== '') &&
            <Alert style={{marginBottom: 10, marginTop: 10}} severity='error'>
                {error}
            </Alert>
            }
        </React.Fragment>
    )
};

export default SubjectProgReportView;
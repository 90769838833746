import axios from 'axios'
import edusim from '../config/edusim';

export const getSectionStatistics = (user, _sectionid) =>{
    return new Promise ((resolve, reject) =>{
        axios.get(edusim.api_base_uri+"/api/instructors/statistics/"+_sectionid,{
            headers: {
            'x-access-token': user.token
            }
        }).then(res => {
            console.log("stats res", res);
            resolve(res.data);
        }).catch(e => {
            reject(e);
        });
    });
}
import React, { Fragment } from 'react';
import { Divider, Card, Icon, Box, Typography } from '@material-ui/core';


export default function StatisticsCard({title, count, percentage, DefaultIcon, color}){
    return(

        <Card style={{overflow: 'visible'/* , width:'20rem' */}}>
      <Box display="flex" justifyContent="space-between" pt={1} px={2}>
        <Box
         style={{background: 'linear-gradient(0deg, '+color+'88 30%, '+color+'bb 90%)'}}
          boxShadow={3}
          borderRadius={16}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
            <DefaultIcon fontSize="large" style={{color: "#ffffff"}}/>
        </Box>
        <Box textAlign="right" lineHeight={1.25}>
          <Typography fontWeight="light" color="primary">
            {title}
          </Typography>
          <Typography variant="h4">{count}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box pb={2} px={2}>
        <Typography component="p" color="primary">
          <Typography
            component="span"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </Typography>
          &nbsp;{percentage.label}
        </Typography>
      </Box>
    </Card>
    )
}
import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';

import Button from '@material-ui/core/Button';


import InputBase from '@material-ui/core/InputBase';

import axios from 'axios';
import edusim from '../config/edusim';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import data from '../config/data';

import StudentGradeSummaryView from './StudentGradeSummaryView';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));

export default function GradeBookView(props) {
  const [loading, setLoading] = React.useState(false);
  const [changesMade, setChangesMade] = React.useState(false);
  //const [assignments, setAssignments] = React.useState([]);
  const [categoryTemplate, setCategoryTemplate] = React.useState({});
  const [studentAssignmentPoints, setStudentAssignmentPoints] = React.useState({});
  const [studentTermScores, setStudentTermScores] = React.useState({});
  const [assignmentPointErrors, setAssignmentPointErrors] = React.useState({});
  const [assignmentsInCategory, setAssignmentsInCategory] = React.useState({});

  //student grade summary
  const [selectedStudent, setSelectedStudent] = React.useState(null);

  const isMounted = React.useRef(null);

  const propsUser = props.user;
  const propsUpdateUser = props.updateUserInformation;
  const propsTermId = props.termid;
  const propsStudents = props.students;
  const propsSectionId = props.sectionid;
  const propsSection = props.section;

  const assignments = propsSection.assignments;

  const classes = useStyles();

  const StudentBadge = React.memo((props) =>{
    const studentid = props.studentid;
    const studentindex = propsStudents.findIndex(s=>s._id===studentid);
    
    return (
        <List style={{width:'100%', minWidth:300}} key={props.key}>
            <ListItem button onClick={(e)=>setSelectedStudent(propsStudents[studentindex])}>
            <ListItemAvatar>
              <Avatar
                  alt={propsStudents[studentindex].name}
                  src={maleAvatarPlaceholder}
              />
            </ListItemAvatar>
            <ListItemText primary={propsStudents[studentindex].name} />
            
          </ListItem>
          </List>
    )
  });

  React.useEffect(()=>{

    isMounted.current = true;
    const loadAssignmentsFromData = () => {
      let sampleData = data;
      //Object.assign(sampleData, data);
      const TermIndex = sampleData.sample_sections.findIndex(e=>e._termid===propsTermId);
      const TermSections = sampleData.sample_sections[TermIndex].sections;
      const Section = TermSections[TermSections.findIndex(e=>e._id===propsSectionId)];
      setCategoryTemplate(Section.categoryTemplate);
      let loadedAssignmentPoints = {};
      Section.assignments.map((obj, key)=>{
        loadedAssignmentPoints[obj._id] = obj.earned_points;
      });
      setStudentAssignmentPoints(loadedAssignmentPoints);
    }

    const loadAssignments = () => {
      let loadedAssignmentsInCategory = {};
      let loadedAssignmentPoints = {};
      console.log("loading assignments", propsSection.assignments);
      setCategoryTemplate(propsSection.categoryTemplate);
      assignments.map((obj, key)=>{
        loadedAssignmentPoints[obj._id] = {...obj.earned_points};
        loadedAssignmentsInCategory[obj._categoryid]?loadedAssignmentsInCategory[obj._categoryid].push(obj)
        :loadedAssignmentsInCategory[obj._categoryid]=[obj];
      });
      setAssignmentsInCategory(loadedAssignmentsInCategory);
      setStudentAssignmentPoints(loadedAssignmentPoints);
      console.log("finished loading assignments");
    };

    //load existing section
    if(propsSectionId&&propsTermId)
      loadAssignments();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [propsSectionId, propsTermId, propsUpdateUser, propsUser]);

  React.useEffect(()=>{

    const updateTermScores = () => {
      //console.log("update term scores called");
      propsStudents.map((student, key)=>{
        let studentCategoryWeightedScore = {};
        studentCategoryWeightedScore[student._id] = {};
        categoryTemplate.categories.map((category, key)=>{
          let studentPointsInCategory = {};
          studentPointsInCategory[student._id] = {};
          let totalPointsInCategory = {};
          totalPointsInCategory[student._id] = {};
          assignmentsInCategory[category._id]&&assignmentsInCategory[category._id].map((assignment, key)=>{
            //only scores relevant to graded assignments
            if(assignment.earned_points){
              if(studentAssignmentPoints[assignment._id][student._id] || studentAssignmentPoints[assignment._id][student._id] === 0){
                studentPointsInCategory[student._id][assignment._categoryid]?studentPointsInCategory[student._id][assignment._categoryid]+=studentAssignmentPoints[assignment._id][student._id]
                :studentPointsInCategory[student._id][assignment._categoryid]=studentAssignmentPoints[assignment._id][student._id];
                totalPointsInCategory[student._id][assignment._categoryid]?totalPointsInCategory[student._id][assignment._categoryid]+=assignment.points
                :totalPointsInCategory[student._id][assignment._categoryid]=assignment.points;
              }
            }
          });
          studentCategoryWeightedScore[student._id][category._id] =  ((studentPointsInCategory[student._id][category._id] /  totalPointsInCategory[student._id][category._id])*100) * category.weightRatio;

        });
        //console.log("from useEffect", studentCategoryWeightedScore);
        let sumOfWeightedScores = 0;
        for (const categoryid in studentCategoryWeightedScore[student._id]){
            sumOfWeightedScores += studentCategoryWeightedScore[student._id][categoryid];
        }
        setStudentTermScores((oldValue)=>({...oldValue, [student._id]: sumOfWeightedScores}));
        
      });
      //console.log("update term scores ended");
    };

    if(propsStudents&&categoryTemplate&&categoryTemplate.categories)
      updateTermScores();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [studentAssignmentPoints, propsStudents, categoryTemplate]);

  const handleGradeFocus = (e) => {
    e.target.setAttribute("data-init", e.target.value);
  }

  const handleGradeBlur = (e) =>{
    console.log(studentAssignmentPoints);
    let newValue = e.target.value;
    let studentid = e.target.getAttribute("data-studentid");
    let assignmentid = e.target.getAttribute("data-assignmentid");
    let assignment = assignments[assignments.findIndex(e=>e._id===assignmentid)];
    let maxPoints = assignment.points + assignment.bonus_points;
    console.log(newValue, studentid, assignmentid);
    if(parseFloat(newValue)!==parseFloat(e.target.getAttribute("data-init"))){

    if(isNaN(newValue) || parseFloat(newValue)>maxPoints){
      e.target.value = e.target.getAttribute("data-init");
      changeShakeState(studentid, assignmentid, true);
      setTimeout(()=>changeShakeState(studentid, assignmentid, false), 500);
      return;
    }
    changeStudentEarnedPoints(studentid, assignmentid, parseFloat(newValue));
  }
   
  }

  const changeShakeState = (studentid, assignmentid, state) =>{
    let errorObject = {...assignmentPointErrors};
    errorObject[assignmentid]?errorObject[assignmentid][studentid]=state
    :errorObject[assignmentid]={};errorObject[assignmentid][studentid]=state
    setAssignmentPointErrors(errorObject);
  }

  const changeStudentEarnedPoints = (studentid, assignmentid, newValue) =>{
    let studentAssignmentPointsCopy = studentAssignmentPoints;
    //let studentAssignmentPointsCopy = Object.assign({},studentAssignmentPoints);
    if(isNaN(newValue)){
      if(studentAssignmentPointsCopy[assignmentid]&&studentAssignmentPointsCopy[assignmentid][studentid]!=null){
        delete studentAssignmentPointsCopy[assignmentid][studentid];
        setStudentAssignmentPoints((oldValue)=>({...studentAssignmentPointsCopy}));
        setChangesMade(true);
      }
      return null;
    }
    studentAssignmentPointsCopy[assignmentid]?studentAssignmentPointsCopy[assignmentid][studentid]=newValue
     :studentAssignmentPointsCopy[assignmentid] = {};studentAssignmentPointsCopy[assignmentid][studentid]=newValue;
    
    setStudentAssignmentPoints((oldValue)=>({...studentAssignmentPointsCopy}));
    setChangesMade(true);
  }

  const saveGradeBook = () =>{
    setLoading(true);
    assignments.map((assignment, key)=>{
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+propsSection._id+"/assignments/points/"+assignment._id, 
      {earned_points: {...studentAssignmentPoints[assignment._id]}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        //update the state
        assignment.earned_points = studentAssignmentPoints[assignment._id];
        setLoading(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                //setAssignmentGradesVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                //setError("Network connection might be lost, ")
            }                    
        }
      });

    });
   /* propsSection.assignments.map((assignment, key)=>{
      assignment.earned_points = studentAssignmentPoints[assignment._id];
    }); */
    setChangesMade(false);
  }

  function getPercent(ratio){
    return (Math.round(((ratio*100) + Number.EPSILON) * 100) / 100);
  }

  function formatPercent(percent){
    return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
  }

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      //color: theme.palette.common.white,
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 2,
      fontWeight: 'bolder'
    },
    body: {
      backgroundColor: "#fff",
      minWidth: "50px",
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 1
    }
  }))(TableCell);
  
  const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);


  return (
    selectedStudent?
      <StudentGradeSummaryView 
      student={selectedStudent} 
      setSelectedStudent={setSelectedStudent}
      section={propsSection}
      sectionid={propsSectionId}
      termid={propsTermId}
      updateUserInformation={propsUpdateUser}
      user={propsUser} />
      :
      <React.Fragment>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>Section Grade Book</Typography>
    
    <TableContainer>
      <Table className={classes.table} aria-label="Grade Book">
        <TableHead>
          <TableRow>
            <StickyTableCell align="left">
              <Button 
              variant="contained" 
              color="primary"
              disabled={changesMade?false:true}
              onClick={saveGradeBook}>
                Save
              </Button>
            </StickyTableCell>
            <TableHeaderCell>
              Calculated
            </TableHeaderCell>
            {categoryTemplate&&categoryTemplate.categories&&categoryTemplate.categories.map((obj, key)=>(
              assignmentsInCategory[obj._id]&&assignmentsInCategory[obj._id].length>0&&
              <TableHeaderCell key={key} align="left" colSpan={assignmentsInCategory[obj._id].length}>
                {obj.name} {formatPercent(getPercent(obj.weightRatio))}
              </TableHeaderCell>
            ))}
          </TableRow>
          <TableRow>
            <StickyTableCell>Student Name</StickyTableCell>
            <TableHeaderCell>Term Weighted Average</TableHeaderCell>
            {categoryTemplate&&categoryTemplate.categories&&categoryTemplate.categories.map((catobj, catkey)=>(
              assignmentsInCategory[catobj._id]?.map((aobj, akey)=>(
                <TableHeaderCell key={akey} align="left">
                {aobj.short_name} {aobj._intervalid&&'('+propsSection.intervals[propsSection.intervals.findIndex(i=>i._id===aobj._intervalid)].short_name+')'}
                {aobj.points&&'('+aobj.points+')'}
                {aobj.bonus_points&&'+'+aobj.bonus_points}
              </TableHeaderCell>
              ))
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {propsStudents&&propsStudents.map((obj, key) => (
            <TableRow key={key}>
              <StickyTableCell><StudentBadge studentid={obj._id}></StudentBadge></StickyTableCell>
              <TableCell>{(studentTermScores&&studentTermScores[obj._id]&&!isNaN(studentTermScores[obj._id])?formatPercent(studentTermScores[obj._id]):"N/A")}</TableCell>
              {categoryTemplate&&categoryTemplate.categories&&categoryTemplate.categories.map((catobj, catkey)=>(
              assignmentsInCategory[catobj._id]?.map((aobj, akey)=>(
                <TableCell key={akey}>
                <InputBase
                  placeholder="E"
                  className={"shakableInput"}
                  defaultValue={studentAssignmentPoints[aobj._id]&&studentAssignmentPoints[aobj._id][obj._id]?studentAssignmentPoints[aobj._id][obj._id]:''}
                  inputProps={{ 
                    'aria-label': 'naked',
                    'data-studentid': obj._id,
                    'data-assignmentid': aobj._id
                  }}
                  shake={assignmentPointErrors[aobj._id]&&assignmentPointErrors[aobj._id][obj._id]?1:0}
                  //causes delay issues
                  //onAnimationEnd={() => changeShakeState(obj._id, aobj._id, false)}
                  onBlur={(e)=>handleGradeBlur(e)}
                  onFocus={(e)=>handleGradeFocus(e)}
                />
                </TableCell>
              ))
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import {
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    withStyles,
    Checkbox
} from '@material-ui/core'

import { 
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Edit as EditIcon,     
} from '@material-ui/icons';

import Box from '@material-ui/core/Box';

import data from '../config/data';

import AssignmentsView from './AssignmentsView';
import ScheduleView from './ScheduleView';
import GradeBookView from './GradeBookView';
import GradeSummaryView from './GradeSummaryView';
import PlanningView from './PlanningView';

import axios from 'axios';
import edusim from '../config/edusim';

const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SubjectObjectivesView = (props) => {
    const subject = props.subject;
    const objectives = props.objectives;

    const [selectedObject, setSelectedObject] = React.useState({});
    const [prevSelectedObject, setPrevSelectedObject] = React.useState(props.prevSelectedObject);
    
    //the parent component setter
    const setSelectedObjectives = props.setSelectedObjectives;

    //the parent component previously selected objectives
    //const propsSelectedObject = {...props.selectedObject};

    React.useEffect(()=>{
      let objectivesObject = {};

      objectives.map((objective, key)=>{
        
        objectivesObject[objective.domain] = {selected:false};
        objective.clusters&&objective.clusters.map((cluster, key)=>{
          objectivesObject[objective.domain][cluster.standard] = {selected:false}
          cluster.children&&cluster.children.map((standard, key)=>{
            objectivesObject[objective.domain][cluster.standard][standard.standard] = {selected: false};
            standard.children&&standard.children.map((substandard, key)=>{
              objectivesObject[objective.domain][cluster.standard][standard.standard][substandard.standard] = {selected: false};
            });
          });
        }); 
      });
      setSelectedObject(objectivesObject);
    }, [objectives]);
    
    React.useEffect(()=>{
      console.log(prevSelectedObject);
      prevSelectedObject&&setSelectedObject(prevSelectedObject);
      prevSelectedObject&&setSelectedObjectives&&setSelectedObjectives(prevSelectedObject);
    }, [prevSelectedObject]);

    const handleObjectiveCheck = (e, domain, cluster, standard, substandard) =>{
      const objSelected = e.target.checked?true:false;
      let tempSelectedObject = {...selectedObject};
      const standardKeys = Object.keys(tempSelectedObject[domain][cluster][standard]);
      if(!substandard){
        tempSelectedObject[domain][cluster][standard].selected = objSelected;
        if(standardKeys.length>1){
          standardKeys.forEach((subkey)=>{
            subkey!=="selected"&&(tempSelectedObject[domain][cluster][standard][subkey].selected = objSelected);
          });
        }
      }
      else {
        tempSelectedObject[domain][cluster][standard][substandard].selected = objSelected;
        objSelected&&(tempSelectedObject[domain][cluster][standard].selected = true);

        if(standardKeys.length>1){
          let selectedCount = 0;
          standardKeys.forEach((subkey)=>{
            subkey!=="selected"&&tempSelectedObject[domain][cluster][standard][subkey].selected&&(selectedCount++);
          });
          if(selectedCount===0){
            tempSelectedObject[domain][cluster][standard].selected = false;
          }
        }
      }
      setSelectedObject(tempSelectedObject);
      setSelectedObjectives&&setSelectedObjectives(tempSelectedObject);
    }

    const [shownClusters, setShownClusters] = React.useState([]);
    
    const toggleCluster = (cluster) => {
      const foundIndex = shownClusters.findIndex((c)=>c.standard===cluster.standard);
      let tempClusters = [...shownClusters];
      if(foundIndex>-1){
        tempClusters.splice(foundIndex,1);
        setShownClusters(tempClusters);
      }else{
        tempClusters.push(cluster);
        setShownClusters(tempClusters);
      }
    }

    const isClusterShown = (cluster) => {
      const foundIndex = shownClusters.findIndex((c)=>c.standard===cluster.standard);
      return foundIndex>-1?true:false;
    }

    const isSelected = (domain, cluster, standard, substandard) =>{
      let tempSelectedObject = {...selectedObject};
      if(!substandard)
        return tempSelectedObject[domain][cluster][standard].selected;
      else
        return tempSelectedObject[domain][cluster][standard][substandard].selected;
    }
    
    const isStandardIndeterminate = (domain, cluster, standard) =>{
      let tempSelectedObject = {...selectedObject};
      const standardKeys = Object.keys(tempSelectedObject[domain][cluster][standard]);
      
      if(standardKeys.length>1){
        let selectedCount = 0;
        standardKeys.forEach((subkey)=>{
          tempSelectedObject[domain][cluster][standard][subkey].selected&&(selectedCount++);
        });
        if(selectedCount!==0&&selectedCount<(standardKeys.length-1))
          return true;
        else
          return false;
      }else{
        return false;
      }
      
    }

    return (
      <React.Fragment>
        
      {!props.progressSelection&&<Typography component="h2" variant="h6" color="primary" >Subject Objectives</Typography>}
      
      <Container maxWidth="xl">
        <Grid container direction="column" spacing={3}>
        {objectives&&objectives.map((objective, key)=>(
          <Grid key={key} item>
          <Grid container direction="column" spacing={2}>
            <Grid item xs><b><Typography variant='h6'><b>{objective.domain}</b></Typography></b></Grid>
            {objective.clusters&&objective.clusters.map((cluster, key)=>(
               <Grid item key={key}>
               <Box style={{cursor: 'pointer'}} onClick={(e)=>toggleCluster(cluster)}><b>{cluster.standard}</b></Box>
               
                {cluster.children&&cluster.children.map((st, key)=>(
                  isClusterShown(cluster)&&
                  <Grid key={key} container>
                    <Grid item style={{paddingLeft:15}}>
                      <Grid container alignItems={props.progressSelection?'baseline':'center'} justify='flex-start' wrap="nowrap">
                        <Grid item>
                        {props.progressSelection&&
                        <Checkbox 
                            onChange={(e)=>handleObjectiveCheck(e, objective.domain, cluster.standard, st.standard)} 
                            checked={isSelected(objective.domain, cluster.standard, st.standard)}
                            indeterminate={isStandardIndeterminate(objective.domain, cluster.standard, st.standard)}
                          />
                        }
                        {!props.progressSelection&&
                        <AssignmentTurnedInIcon />
                        }
                        </Grid>
                        <Grid item xs>
                          {st.standard} {/* {selectedObject[objective.domain]&&selectedObject[objective.domain][cluster.standard]&&selectedObject[objective.domain][cluster.standard][st.standard]&&selectedObject[objective.domain][cluster.standard][st.standard].selected?'SEL':'USEL'} */}
                        </Grid>
                      </Grid>
                    </Grid>
                    {st.children&&st.children.map((child, key)=>(
                      <Grid key={key} container>
                        <Grid item style={{paddingLeft:30}}>
                          <Grid container alignItems={props.progressSelection?'baseline':'center'} justify='flex-start' wrap="nowrap">
                          <Grid item>
                          {props.progressSelection&&
                            <Checkbox 
                              onChange={(e)=>handleObjectiveCheck(e, objective.domain, cluster.standard, st.standard, child.standard)} 
                              checked={isSelected(objective.domain, cluster.standard, st.standard, child.standard)}
                            />
                          }
                          {!props.progressSelection&&
                          <AssignmentTurnedInIcon />
                          }
                          </Grid>
                          <Grid item xs>
                            {child.standard}
                          </Grid>                          
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ))}
               </Grid>
            ))}
          </Grid>
          </Grid>
        ))}
        </Grid>
      </Container>
      </React.Fragment>
    )
  
}
export default SubjectObjectivesView;
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import edusim from '../config/edusim';
import splash from '../imgs/splash.jpg';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cactisoft.com/">
        Cacti Software ™ 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    /* backgroundImage: 'url(https://source.unsplash.com/random)', */
    backgroundImage: "url(" + splash + ")",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatarProgress: {
    //color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }
}));

export default function SignInSide(props) {
  const isMounted = React.useRef(null);
  const [activeComponent, setActiveComponent] = useState(props._resettoken?'newPass':'signIn');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [signupValidationOn, setSignupValidationOn] = useState({validate: false, callback:null});
  const [realname,setRealname] = useState('');
  const [realnameError,setRealnameError] = useState({});
  const [susername,setSusername] = useState('');
  const [susernameError,setSusernameError] = useState({});
  const [spassword,setSpassword] = useState('');
  const [spasswordError,setSpasswordError] = useState({});
  const [termsChecked,setTermsChecked] = useState(false);
  const [termsCheckedError,setTermsCheckedError] = useState({});
  const [signinValidationOn, setSigninValidationOn] = useState({validate: false, callback:null});
  const [username,setUsername] = useState('');
  const [usernameError,setUsernameError] = useState({});
  const [password, setPassword] = useState('');
  const [passwordError,setPasswordError] = useState({});

  const [rpassword,setRpassword] = useState('');
  const [rpasswordError,setRpasswordError] = useState({});
  const [resetPasswordValidationOn, setResetPasswordValidationOn] = useState({validate: false, callback:null});
  const [resetPassToken, setResetPassToken] = useState(props._resettoken);

  const classes = useStyles();

  const errorMessage = (
    error !== '' && <Alert severity="error">{error}</Alert>
  );

  const successMessage = (
    success !== '' && <Alert severity="success">{success}</Alert>
  );

  React.useEffect(()=>{
    
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);

  React.useEffect(()=>{

    isMounted.current = true;

    const validateSignUpFields = () => {
      const hasNumber = value => {
        return new RegExp(/[0-9]/).test(value);
      }
      const hasMixed = value => {
          return new RegExp(/[a-z]/).test(value) &&
                  new RegExp(/[A-Z]/).test(value);
      }
      const validEmail = value => {
        return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
      }
      let valid = true;
      if(!realname){
        valid = false;
        setRealnameError({error:true, message:'Required!'});
      }else if(realname.length < 5){
        valid = false;
        setRealnameError({error:true, message:'Must be at least 5 characters!'});
      }else{
        setRealnameError({error:false, message:null});
      }

      if(!susername){
        valid = false;
        setSusernameError({error:true, message:'Required!'});
      }else if(!validEmail(susername)){
        valid = false;
        setSusernameError({error:true, message:'Must be a valid email address!'});
      }/* else if(validEmail(susername) && susername.split("@")[1] !== edusim.valid_instructor_domain){
        valid = false;
        setSusernameError({error:true, message:'Must be @'+edusim.valid_instructor_domain});
      } */else{
        setSusernameError({error:false, message:null});
      }

      if(!spassword){
        valid = false;
        setSpasswordError({error:true, message:'Required!'});
      }else if(!hasMixed(spassword)||!hasNumber(spassword)||spassword.length<8){
        valid = false;
        setSpasswordError({error:true, message:'Must be at least 8 characters containing mixed case and number!'});
      }else{
        setSpasswordError({error:false, message:null});
      }

      if(!termsChecked){
        valid = false;
        setTermsCheckedError({error:true, message:'You must confirm this!'});
      }else{
        setTermsCheckedError({error:false, message:null});
      }

      
      
      if(signupValidationOn.callback)
        signupValidationOn.callback(valid);
    }

    if(signupValidationOn.validate)
      validateSignUpFields();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[realname, susername, spassword, termsChecked, signupValidationOn]);
  
  React.useEffect(()=>{
    
    const validateSignInFields = () => {

      const validEmail = value => {
        return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
      }

      let valid = true;

      if(!username){
        valid = false;
        setUsernameError({error:true, message:'Required!'});
      }else if(!validEmail(username)){
        valid = false;
        setUsernameError({error:true, message:'Must be a valid email address!'});
      }else{
        setUsernameError({error:false, message:null});
      }

      if(!password){
        valid = false;
        setPasswordError({error:true, message:'Required!'});
      }else if(password.length<8){
        valid = false;
        setPasswordError({error:true, message:'Must be at least 8 characters!'});
      }else{
        setPasswordError({error:false, message:null});
      }
      
      if(signinValidationOn.callback)
        signinValidationOn.callback(valid);
    }

    if(signinValidationOn.validate)
      validateSignInFields();

    
  },[username, password, signinValidationOn]);

  React.useEffect(()=>{
    const validateResetPassFields = () => {
      const hasNumber = value => {
        return new RegExp(/[0-9]/).test(value);
      }
      const hasMixed = value => {
          return new RegExp(/[a-z]/).test(value) &&
                  new RegExp(/[A-Z]/).test(value);
      }

      let valid = true;

      if(!rpassword){
        valid = false;
        setRpasswordError({error:true, message:'Required!'});
      }else if(!hasMixed(rpassword)||!hasNumber(rpassword)||rpassword.length<8){
        valid = false;
        setRpasswordError({error:true, message:'Must be at least 8 characters containing mixed case and number!'});
      }else{
        setRpasswordError({error:false, message:null});
      }
      
      if(resetPasswordValidationOn.callback)
        resetPasswordValidationOn.callback(valid);
    }
    if(resetPasswordValidationOn.validate)
      validateResetPassFields();
  },[rpassword, resetPasswordValidationOn]);
  
  const signUp = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        {successMessage}
        {errorMessage}
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={68} className={classes.avatarProgress} />}
        </div>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} /* noValidate */>
          <TextField
              autoComplete="name"
              error={realnameError['error']? true: false}
              helperText={realnameError['message']?realnameError['message']: null}
              name="fullName"
              variant="outlined"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              onChange={(e)=> setRealname(e.target.value)}
              onKeyPress={(e) => { signUpEnterKey(e);}}
              autoFocus
          />
          <TextField
            error={susernameError['error']? true: false}
            helperText={susernameError['message']?susernameError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e)=> setSusername(e.target.value)}
            onKeyPress={(e) => { signUpEnterKey(e);}}
          />
          <TextField
            error={spasswordError['error']? true: false}
            helperText={spasswordError['message']?spasswordError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=> setSpassword(e.target.value)}
            onKeyPress={(e) => { signUpEnterKey(e);}}
          />
          <FormControl required error={termsCheckedError['error']? true: false} component="fieldset">
          <FormControlLabel
            control={
            <Checkbox 
            checked={termsChecked} 
            onChange={(e)=>setTermsChecked(e.target.checked)} 
            name="terms" 
            color="primary"
            />}
            label={<span style={{ 'fontSize': '1rem' }}>
              I hereby accept the <Link component="button" onClick={() => setActiveComponent('terms')} variant="body2">
                {"Terms and Conditions of Use"}
              </Link>. I also understand that by signing up, I might receive a verification code to my phone number by SMS. Message and data rates may apply accordingly.
            </span>}
          />
          <FormHelperText>{termsCheckedError['message']?termsCheckedError['message']: ''}</FormHelperText>
          </FormControl>
           
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>signUpUser()}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" onClick={() => setActiveComponent('forgotPass')} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signIn')} variant="body2">
                {"Sign In"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const signIn = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        {successMessage}
        {errorMessage}
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={68} className={classes.avatarProgress} />}
        </div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={usernameError['error']? true: false}
            helperText={usernameError['message']?usernameError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e)=> setUsername(e.target.value)}
            onKeyPress={(e) => { signInEnterKey(e);}}
            autoFocus
          />
          <TextField
            error={passwordError['error']? true: false}
            helperText={passwordError['message']?passwordError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=> setPassword(e.target.value)}
            onKeyPress={(e) => { signInEnterKey(e);}}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>signInUser()}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" onClick={() => setActiveComponent('forgotPass')} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signUp')} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const forgotPass = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        {successMessage}
        {errorMessage}
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={68} className={classes.avatarProgress} />}
        </div>
        <Typography component="h1" variant="h5">
          Password Reset: Enter Your Email
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={usernameError['error']? true: false}
            helperText={usernameError['message']?usernameError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e)=> setUsername(e.target.value)}
            onKeyPress={(e) => { sendResetPassEnterKey(e);}}
            autoFocus
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>sendResetPasswordRequest()}
          >
            Request Reset
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" onClick={() => setActiveComponent('signIn')} variant="body2">
                Sign In
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signUp')} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const newPass = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        {successMessage}
        {errorMessage}
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={68} className={classes.avatarProgress} />}
        </div>
        <Typography component="h1" variant="h5">
          Password Reset: Enter Your New Password
        </Typography>
        <form className={classes.form} noValidate>
        <TextField
            error={rpasswordError['error']? true: false}
            helperText={rpasswordError['message']?rpasswordError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=> setRpassword(e.target.value)}
            onKeyPress={(e) => { resetPasswordEnterKey(e);}}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>resetPassword()}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" onClick={() => setActiveComponent('signIn')} variant="body2">
                Sign In
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signUp')} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const termsAndConditions = (
  <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Terms and Conditions of Use
        </Typography>

        <Typography>
          <p>
          By signing up to use our services, you agree implicitly to the following terms and conditions:
          </p>
          <ol>
            <li>
            You will use this Beta application as is for testing purposes with no implied warranties.
            </li>
            <li>
            You are a current active instructor at the school. You attest ownership of the email address and mobile phone number you will provide during the signup process.
            </li>
            <li>
            You will need to provide a mobile phone number for means of communication and account verification. We might send you verification codes via SMS or personally call you to verify your identity and activate your account. Some data or messaging fees might be charged to you by your provider. You will not be able to commence any action on the platform unless your mobile phone number has been verified.
            </li>
            <li>
            We will not share or disclose any information whatsoever related to your account with any third party. For your security and privacy, you should not share your account details with anyone.
            </li>
            <li>
            Our agents or affiliates will never ask you for your password or verification OTPs. However, they might ask for other information related to your account for verification purposes.
            </li>

          </ol>

        </Typography>
        <Grid container>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signUp')} variant="body2">
                {"Back to Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
      </div>
    </Grid>
  </Grid>
  );

  const signInEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      signInUser()
    }
  }

  const signUpEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      signUpUser()
    }
  }

  const resetPasswordEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      resetPassword();
    }
  }

  const sendResetPassEnterKey = (e) => {
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      sendResetPasswordRequest();
    }
  }

  const signInUser = () =>{
    setSigninValidationOn({validate: true, callback: (valid)=>{
      if(!valid){
        setError('Please fix the required information before proceeding.');
        setSigninValidationOn({validate: true, callback:null});
      }else{
        setLoading(true);
        axios.post(edusim.api_base_uri + '/authenticate', 
          { 
            username: username, 
            password: password, 
          })
          .then(res => {
            //setLoading(false);
            let userData = res.data;
            axios.get(edusim.api_base_uri + '/api/getGuardianApplicant', {
              headers: {
                'x-access-token': userData.token
              }
            })
            .then(res=>{
              userData.guardian = res.data;
            }).catch((error)=>{
            }).finally(()=>{
              console.log(userData);
              props.updateUserInformation(userData);
            });
            
          }).catch( (error) => {
            console.log(error);
            //alert(error);
            setLoading(false);
            let errmsg = ""
            if(error.response){
              errmsg = error.response.data.message;
            }else{
              errmsg = "Something went wrong while reaching the API service. Are you connected to the internet?";
            }
            setError(errmsg);
            setSigninValidationOn({validate: true, callback:null});
          });
          
          }
        }})
    
  };

  const signUpUser = () =>{
    setSignupValidationOn({validate: true, callback: (valid)=>{
      if(!valid){
        setError('Please fix the required information before proceeding.');
        setSignupValidationOn({validate: true, callback:null});
      }else{
        setLoading(true);
        axios.post(edusim.api_base_uri + '/instructorsignup', 
          { realname: realname,
            username: susername, 
            password: spassword, 
            passwordagain: spassword,
          })
          .then(res => {
            setLoading(false);
            setSuccess('Successfully Signed Up. Check your email for activation information.');
          }).catch( (error) => {
            console.log(error);
            //alert(error);
            setLoading(false);
            let errmsg = ""
            if(error.response){
              errmsg = error.response.data.message;
            }else{
              errmsg = "Something went wrong while reaching the API service. Are you connected to the internet?";
            }
            setError(errmsg);
          });
      }
    }})
    
    //setTimeout(()=>{setLoading(false);setSuccess('Successfully Signed Up. Check your email for activation information.')}, 3000);
  };

  const sendResetPasswordRequest = () =>{
    console.log("Send Password Reset Called");
    setLoading(true);
    axios.post(edusim.api_base_uri + '/forgotPassword', 
      { username: username,
      })
      .then(res => {
        setLoading(false);
        setSuccess('Successfully Sent Your Request. Check you email for further instructions.');
      }).catch( (error) => {
        console.log(error);
        //alert(error);
        setLoading(false);
        let errmsg = ""
        if(error.response){
          errmsg = error.response.data.message;
        }else{
          errmsg = "Something went wrong while reaching the API service. Are you connected to the internet?";
        }
        setError(errmsg);
      });
  }

  const resetPassword = () =>{
    console.log("Reset Password Called", resetPassToken);
    setResetPasswordValidationOn({validate: true, callback: (valid)=>{
      if(!valid){
        setError('Please fix the required information before proceeding.');
        setResetPasswordValidationOn({validate: true, callback:null});
      }else{
        setLoading(true);
        axios.post(edusim.api_base_uri + '/forgotPassword/'+resetPassToken, 
          { token: resetPassToken,
            password: rpassword,
          })
          .then(res => {
            setLoading(false);
            setSuccess('Successfully Reset Your Password. Please Sign In.');
          }).catch( (error) => {
            console.log(error);
            //alert(error);
            setLoading(false);
            let errmsg = ""
            if(error.response){
              errmsg = error.response.data.message;
            }else{
              errmsg = "Something went wrong while reaching the API service. Are you connected to the internet?";
            }
            setError(errmsg);
          });
      }
    }});
  }

  return (
  <div>
   {activeComponent === 'signUp' && signUp}
   {activeComponent === 'signIn' && signIn}
   {activeComponent === 'forgotPass' && forgotPass}
   {activeComponent === 'newPass' && newPass}
   {activeComponent === 'terms' && termsAndConditions}
  </div>
  );
}

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {Select, MenuItem} from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Alert from '@material-ui/lab/Alert';
import { Api } from "../services/Api";
import { getSectionStatistics } from '../services/Statistician';

import { VictoryChart, VictoryGroup, VictoryArea, VictoryTheme } from 'victory';

import MessagesView from './MessagesView';

import StatisticsCard from '../components/StatisticsCard';
import {AccountBalanceRounded as AccountBalanceRoundedIcon } from '@material-ui/icons';
import LineGraphCard from '../components/LineGraphCard';
import { CartesianAxis } from 'recharts';

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://cacticonsult.com/">
        Cacti Software ™ 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  graphPaper:{
    padding: theme.spacing(2),
    width: "100%",
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  graphChart:{
    width: '100%',
    maxHeight: 300
  },
  fixedHeight: {
    height: 240,
  },
}));

function Dashboard(props){
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [selectedTermId, setSelectedTermId] = React.useState(null);
  const [yearsObject, setYearsObject] = React.useState({});
  const [sections, setSections] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);

  const [sectionAverages, setSectionAverages] = React.useState({});
  const [sectionAveragesData, setSectionAveragesData] = React.useState([]);

  function getYearsObject(terms){
    let yearsObj = {};
    terms.map((term)=>{
      yearsObj[term.year]?yearsObj[term.year].push(term):yearsObj[term.year]=[term];
    });
    return yearsObj;
  }

  const handleYearChange = (e) =>{
    let year = e.target.value;
    setSelectedYear(year);
  }

  const handleTermChange = (e) =>{
    let termId = e.target.value;
    setSelectedTermId(termId);
  }

  function getTermName(){
    const foundIndex = yearsObject[selectedYear]?.findIndex(t=>t._id===selectedTermId);
    return (yearsObject[selectedYear]&&yearsObject[selectedYear][foundIndex])?yearsObject[selectedYear][foundIndex].name:'';
  }

  React.useEffect(()=>{
    setSelectedTermId((selectedYear&&yearsObject[selectedYear]&&yearsObject[selectedYear].length>0)?yearsObject[selectedYear][0]._id:null)
  }, [selectedYear])

  React.useEffect(() => {
    Promise.all([
      Api.getTermsLevels(props.user),
    ]).then(([termsLevels]) => {
      setYearsObject(getYearsObject(termsLevels.terms));
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  function updateSectionAverage(sectionStatObj){

    let catTotalPoints = {};
    let earnedPointsInCat = {};
    //prepare needed calculations
    sectionStatObj.assignments.map((assignment, key)=>{
      if(assignment.earned_points && assignment.points){
        let numStudentsInAssignment = Object.keys(assignment.earned_points).length;
        let sumOfStudentPoints = 0;
        for (const studentid in assignment.earned_points) {
          sumOfStudentPoints += assignment.earned_points[studentid];
        }
        let averageAssignmentScore = (sumOfStudentPoints/(numStudentsInAssignment*assignment.points))*100;
        //setAssignmentAverages((currentValue)=> ({...currentValue, [assignment._id] : averageAssignmentScore}));
        
        if(assignment._categoryid){
          
          catTotalPoints[assignment._categoryid] = catTotalPoints[assignment._categoryid]?
          catTotalPoints[assignment._categoryid]+(assignment.points*numStudentsInAssignment):
          assignment.points*numStudentsInAssignment;

          earnedPointsInCat[assignment._categoryid] = earnedPointsInCat[assignment._categoryid]?
          earnedPointsInCat[assignment._categoryid]+sumOfStudentPoints:
          sumOfStudentPoints;
        }
      }

    });

    //calculate term average
    let termScore = 0;
    for (const categoryid in catTotalPoints){
      if(catTotalPoints[categoryid]>0){
        let categoryAverage = catTotalPoints[categoryid]>0?(earnedPointsInCat[categoryid]/catTotalPoints[categoryid])*100:0;
        let weightedScore = categoryAverage * categoryWeightRatio(categoryid, sectionStatObj.categoryTemplate);
        termScore += weightedScore;
      }
    }
    
    setSectionAverages((currentValue)=>(
      {...currentValue, 
        [sectionStatObj._id]: termScore
      }));
    
  }

  const categoryWeightRatio = (_categoryid, categoryTemplate) => {
    let foundIndex = categoryTemplate.categories.findIndex(e=>e._id==_categoryid);
    return foundIndex>=0?categoryTemplate.categories[foundIndex].weightRatio:0;
  }

  function getSectionAveragesData(){
    let dataArr=[];
    sections.map((section, key)=>{
      let secObj = {
        'x': section._subjectid.short_name + ' ' + section._groupid.short_name + ' ' + key,
        'y': sectionAverages[section._id]
      }
      dataArr.push(secObj);
    });
    return dataArr;
  }

  React.useEffect(() => {
    Promise.all([
      Api.getMySections(props.user, selectedTermId)
    ]).then(([sections]) => {
      setSections(sections);
      const uniqueSubjects = [...new Map(sections.map(item => [item['_subjectid']._id, item._subjectid])).values()];
      const uniqueGroups = [...new Map(sections.map(item => [item['_groupid']._id, item._groupid])).values()];
      setGroups(uniqueGroups);
      setSubjects(uniqueSubjects);  

      sections.map((section, key)=>{
        getSectionStatistics(props.user, section._id).then((res)=>{
          updateSectionAverage(res);
        }).catch(e=>{
          console.log(e);
        })
      })

    }).catch((e)=>{
      console.log(e);
    });
  }, [selectedTermId]);

  React.useEffect(()=>{
    let initialSelectedYear = Object.keys(yearsObject).length>0?Object.keys(yearsObject)[0]:null;
    let initialSelectedTermId = (initialSelectedYear&&yearsObject[initialSelectedYear].length>0)?yearsObject[initialSelectedYear][0]._id:null;
    setSelectedYear(initialSelectedYear);
    setSelectedTermId(initialSelectedTermId);
  }, [yearsObject]);

  return(
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  Both your email and mobile must be verified to use this service
              </Alert>
            </Grid>
    :
    <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            {(props.error !== '') &&
            <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  {props.error}
              </Alert>
            </Grid>
            }
            {/* Important Information */}
            <Grid item xs={12}>
              <Grid container spacing={3} justify={"space-between"}>
                <Grid item>
                <Select
                        variant='outlined'
                        value={selectedYear||''}
                        onChange={(e) => handleYearChange(e)}>
                        {yearsObject&&Object.keys(yearsObject).map((key, index)=>(
                            <MenuItem key={index} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                        </Select>
                </Grid> 
                <Grid item>
                <Select
                variant='outlined'
                value={selectedTermId||''}
                onChange={(e) => handleTermChange(e)}>
                {yearsObject[selectedYear]?.map((term, key)=>(
                    <MenuItem key={key} value={term._id}>
                        {term.name}
                    </MenuItem>
                ))}
                </Select>
              </Grid>
              </Grid>
            
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              
                <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="primary"
                  >
                    Overview
                  </Typography>
                </Grid>
               
                <Grid item xs={12} md={6} lg={3}>
                
                <StatisticsCard title="Sections" count={sections.length} color="#ff0000" DefaultIcon={AccountBalanceRoundedIcon} 
                  percentage={{
                    //color: "success",
                    amount: "",
                    label: "in " + getTermName(selectedTermId) + ' ' + selectedYear,
                  }}
                />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                
                <StatisticsCard title="Student Groups" count={groups.length} color="#00ff00" DefaultIcon={AccountBalanceRoundedIcon} 
                  percentage={{
                    //color: "success",
                    amount: "",
                    label: "in " + getTermName(selectedTermId) + ' ' + selectedYear,
                  }}
                />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                
                <StatisticsCard title="Subjects" count={subjects.length} color="#0000ff" DefaultIcon={AccountBalanceRoundedIcon} 
                  percentage={{
                    //color: "success",
                    amount: "",
                    label: "in " + getTermName(selectedTermId) + ' ' + selectedYear,
                  }}
                />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                
                <StatisticsCard title="Plans" count={0} color="#4ed122" DefaultIcon={AccountBalanceRoundedIcon} 
                  percentage={{
                    //color: "success",
                    amount: "",
                    label: "in " + getTermName(selectedTermId) + ' ' + selectedYear,
                  }}
                />
                </Grid>
                </Grid>
                
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {/* <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.graphPaper}>
              <VictoryChart>
                <VictoryGroup
                  style={{
                    data: { strokeWidth: 3, fillOpacity: 0.4 }
                  }}
                >
                  <VictoryArea
                    style={{
                      data: { fill: "cyan", stroke: "cyan" }
                    }}
                    data={[
                      { x: 1, y: 2 },
                      { x: 2, y: 3 },
                      { x: 3, y: 5 },
                      { x: 4, y: 4 },
                      { x: 5, y: 7 }
                    ]}
                  />
                  <VictoryArea
                    style={{
                      data: { fill: "magenta", stroke: "magenta" }
                    }}
                    data={[
                      { x: 1, y: 3 },
                      { x: 2, y: 2 },
                      { x: 3, y: 6 },
                      { x: 4, y: 2 },
                      { x: 5, y: 6 }
                    ]}
                  />
                </VictoryGroup>
              </VictoryChart>
              </Paper>
                </Grid> */}
                <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.graphPaper}>
                <LineGraphCard getSectionAveragesData={getSectionAveragesData} />
              </Paper>
                </Grid> 
              </Grid>
             
            </Grid>
            
            {/* <Grid item xs={6}>
              <Paper className={classes.paper}>
              <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Messages
                </Typography>
                <MessagesView user={props.user} />
              </Paper>
            </Grid> */}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
  );
}

export default Dashboard;
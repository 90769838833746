import React from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    Button,
    CircularProgress,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Tooltip,
    makeStyles,
    Box,
    Fab
} from '@material-ui/core';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon, 
    Add as AddIcon    
} from '@material-ui/icons';

import FloatingActionButton from './FAB';

import AssignmentGradeEntry from '../views/AssignmentGradeEntry';
import AssignmentView from '../views/AssignmentView';
import axios from 'axios';
import edusim from '../config/edusim';
import SectionProgReportView from './SectionProgReportView';

const SectionProgressView = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const [selectedReport, setSelectedReport] = React.useState(null);
    const [currentReports, setCurrentReports] = React.useState([]);
   
    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const sectionId = props.sectionId;
    
    const intervals = props.intervals;

    const students = props.students;

    const isMounted = React.useRef(null);  

    React.useEffect(()=>{

        isMounted.current = true;
        
        const loadSectionReports = () => {
          setLoading(true);
          axios.get(edusim.api_base_uri+"/api/instructors/sections/"+sectionId+"/progressReports",{
            headers: {
              'x-access-token': propsUser.token
            }
          }).then(res => {
            console.log(res);
            setCurrentReports(res.data);
            setLoading(false);
          }).catch(e => {
            if(e.response){
                if(isMounted.current){
                    setLoading(false);
                }
                if(e.response.status === 403){
                    propsUpdateUser({});
                }
            }else{
                if(isMounted.current){
                    setLoading(false);
                    setError("Network connection might be lost, ")
                }                    
            }
          });
        };
    
        //load existing section
        if(sectionId)
          loadSectionReports();
    
        return () => {
          // executed when unmount
          isMounted.current = false;
        }
    }, [sectionId, propsUpdateUser, propsUser]);
    
    function progressReportsInGradingInterval(_intervalid){
        return currentReports.filter((r)=>r._gradingintervalid===_intervalid);
    }

    function getIntervalName(_intervalid){
        const intervalIndex = intervals.findIndex(i=>i._id===_intervalid);
        return intervals[intervalIndex].name;
    }

    const handleReportClick = (e, report) =>{
        setSelectedReport(report);
    }

    return (
        <React.Fragment>
            {
            selectedReport?
                <SectionProgReportView 
                    selectedReport={selectedReport}
                    setSelectedReport={setSelectedReport}
                    students={students}
                    sectionId={sectionId}
                    intervalName={getIntervalName(selectedReport._gradingintervalid)}
                    propsUser={propsUser}
                    propsUpdateUser={propsUpdateUser}
                    schoolObjectives={props.schoolObjectives}
                    schoolObjectivesRating={props.schoolObjectivesRating}
                />
            :
            <React.Fragment>
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item>
                        <Typography component="h2" variant="h6" color="primary" >Progress Reports</Typography>
                    </Grid>
                </Grid>
            
                <Grid container alignItems='center' justify='space-between'>
                {currentReports?.map((report, key)=>(
                    <Grid item xs>
                    <Button key={key} onClick={(e)=>handleReportClick(e, report)}>{report.name} - {report._termid.name} {report._termid.year} - {getIntervalName(report._gradingintervalid)} </Button>
                    </Grid>
                ))}
                </Grid>

            </React.Fragment>
        }
        </React.Fragment>
        
    )
};
export default SectionProgressView;
import React, { Fragment } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {
    Typography,
    TextField,
    LinearProgress,
    CircularProgress,
    Grid,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Avatar,
    IconButton,
    makeStyles,
    Card,
    CardHeader,
    CardContent,
    FormControl,
    InputLabel,
    InputBase,
    Select,
    MenuItem,
    Checkbox,
    InputAdornment
} from '@material-ui/core';

import {Description as DescIcon, 
  Delete as DeleteIcon, 
  ListAltSharp as ListAltSharpIcon, 
  Close as CloseIcon,
  Add as AddIcon} from '@material-ui/icons';

import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import axios from 'axios';
import edusim from '../config/edusim';
import {DropzoneArea} from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    checkboxGrid: {
      border: `${fade(theme.palette.text.primary, 0.2)} 1px solid` ,
      //marginTop: "5px",
      padding: "10px",
      paddingTop: "40px"
    },
    dropZoneClass:{
      maxHeight: '200px'
    },
    uploadButton: {
      width:'100%'
    },
}));

const BucketDocumentsView = (props) =>{
  const classes = useStyles();
  const bucket = props.bucket;
  const updateExistingBucket = props.updateExistingBucket;
  const section = props.section;
  const assignment = props.assignment;
  
  const propsUser = props.propsUser;
  const setError = props.setError;
  const assignmentBucketsVisible = props.assignmentBucketsVisible;

  const [filesUploadProgress, setFilesUploadProgress] = React.useState(0);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [bucketAttachments, setBucketAttachments] = React.useState([]);
  const [attachmentsInfoLoading, setAttachmentsInfoLoading] = React.useState(false);
  const [removingFile, setRemovingFile] = React.useState({});
  const [dropZoneKey, setDropZoneKey] = React.useState(0);

  const _uploadDocumentsProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    setFilesUploadProgress(percent);
    if(percent >= 100){
      setFilesUploadProgress(0);
    }
  }

  const _uploadBucketDocuments = () =>{
    console.log("Uploading bucket documents");

    if(selectedDocuments.length > 0){

    let formData = new FormData();

    selectedDocuments.forEach((file)=>{
      formData.append('bucketDocuments[]', file, file.name);
    });

    //formData.append("_planid", plan._id);

    axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/"+assignment._id+"/buckets/"+bucket._id+"/uploadBucketAttachments", formData,{
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': propsUser.token
      },
      onUploadProgress: _uploadDocumentsProgress
    }).then(res => {
      _getAttachmentsInfo();
      setFilesUploadProgress(0);
      setDropZoneKey(dropZoneKey + 1);
    }).catch(e => {
      if(e.response){
        if(e.response.status === 403){
          setError("Permission Problem - Seems that you might have logged off or your session has expired. Try to log in again.");
        }else if(e.response.status === 501){
          setError("Size Problem - Total uploads should be a maximum of 20 document and images and not more than 100MB combined");
        }
        else{
          setError("General Problem - There seems to be an issue with the API service at the moment. Please try again later.");
        }
      }
      else{
        setError("Network Problem - There seems to be an issue with the connection. Are you connected to the internet?");
      }
      setFilesUploadProgress(0);
      console.log(e);
    });

  }
  }

  const handleFilesSelect = (files) => {
    setSelectedDocuments(files);
  }

  const handleFilesUpload = () => {
    _uploadBucketDocuments();
  }

  const handleFileSelectError = (msg) => {
    setError(msg);
  }

  const _getAttachmentsInfo = () =>{
    setAttachmentsInfoLoading(true);
    axios.get(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/assignments/'+assignment._id+'/buckets/'+bucket._id+'/getBucketAttachmentsInfo', {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
    console.log(res.data);
     setAttachmentsInfoLoading(false);
     setBucketAttachments(res.data);
     updateBucketAttachmentIds(res.data);
    }).catch(e =>{
      setAttachmentsInfoLoading(false);
      console.log(e)
    });
  }

  const updateBucketAttachmentIds= (attachmentsInfo)=>{
    if(attachmentsInfo){
        let fileids = [];
        attachmentsInfo.map((fileinfo, key)=>{
            fileids.push(fileinfo._id);
        });
        console.log(fileids);
        let tempBucket = {...bucket};
        tempBucket.attachments = fileids;
        updateExistingBucket(tempBucket);
     }
  }

  const removeBucketAttachment = (fileid) =>{
    let newAttachments = bucketAttachments.filter(e=>e._id!==fileid);
    setBucketAttachments(newAttachments);
    updateBucketAttachmentIds(newAttachments);
  }

  const _removeUploadedFile = (fileid) =>{
    setRemovingFile(removingFile=> ({...removingFile, [fileid]: true}));
    axios.post(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/assignments/'+assignment._id+'/buckets/'+bucket._id+'/removeBucketAttachment', {_fileid: fileid}, {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      removeBucketAttachment(fileid);
    }).catch(e =>{
      console.log(e);
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      setError("Cannot remove the file at the moment, please try again later.");
    });
  }

  const handleFileRemove = (fileid) =>{
    _removeUploadedFile(fileid);
  }

  const handleFileDownload = (fileid) => {
    window.open(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/assignments/'+assignment._id+'/buckets/'+bucket._id+'/getBucketAttachment/'+fileid
    +"?token="+propsUser.token);
  }
  

  React.useEffect(()=>{
    if(assignmentBucketsVisible) _getAttachmentsInfo();
  },[assignmentBucketsVisible]); 

    return (
      <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
          <DropzoneArea
              key={dropZoneKey}
              dropzoneClass={classes.dropZoneClass}
              onChange={handleFilesSelect}
              showPreviews={true}
              showPreviewsInDropzone={false}
              filesLimit={20}
              maxFileSize={100000000}
              showAlerts={false}
              previewText="Selected Files:"
              previewGridProps={{container: {alignItems:'flex-start', wrap:'wrap'},item:{xs:'auto',sm:'auto',md:'auto',lg:'auto'}}}
              getFileLimitExceedMessage={(limit) => handleFileSelectError('You can only upload '+limit+ ' files at a time.')}
          />
          </Grid>
          <Grid item xs={12} sm={12}>
              <Button color="primary" variant="outlined" 
              className={classes.uploadButton} 
              onClick={handleFilesUpload} disabled={filesUploadProgress>0?true:false}>UPLOAD SELECTED</Button>
              {filesUploadProgress>0 && <LinearProgress variant="determinate" value={filesUploadProgress} />}
              <List dense={false}>
              {!attachmentsInfoLoading && bucketAttachments.map((obj, key)=>
              (<ListItem button key={key} onClick={()=>handleFileDownload(obj._id)} disabled={removingFile[obj._id]?true:false}>
                  <ListItemAvatar>
                  <Avatar>
                      <DescIcon />
                  </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                  primary={obj.filename}
                  primaryTypographyProps={{ noWrap: true }}
                  secondary={obj.contentType + " ("+ Math.floor(obj.length/1024)+" KB)"}
                  />
                  <ListItemSecondaryAction>
                  {removingFile[obj._id] && <CircularProgress />}
                  {!removingFile[obj._id] &&
                  <IconButton onClick={()=>handleFileRemove(obj._id)} edge="end" aria-label="delete">
                      <DeleteIcon />
                  </IconButton>}
                  </ListItemSecondaryAction>
              </ListItem>

              ))} 
              {attachmentsInfoLoading && <CircularProgress size={50} className={classes.inButtonProgress} />} 
              </List>
          </Grid>
          </Grid>
    )
}

const BucketStudentsView = (props) =>{
  const students = props.students;
  const bucket = props.bucket;

  const updateSelectedStudents = props.updateSelectedStudents;

  const [selectedStudents, setSelectedStudents] = React.useState(bucket&&bucket.students?bucket.students:[]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
      getContentAnchorEl: () => null,
      PaperProps: {
          style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
          },
  },
  };

  const handleStudentsSelectChange = (event) =>{
    const value = event.target.value;
    console.log(value);
    if(value[value.length - 1] === "all"){
      let allStudentids = [];
      students.map((s)=>allStudentids.push(s._id));
      const newStudents = (selectedStudents.length === students.length) ? [] : allStudentids;
      setSelectedStudents((old)=>([...newStudents]));
      updateSelectedStudents(bucket, newStudents);
      return;
    }

    let newStudents = value;
    newStudents = (typeof value === 'string')?value.split(','):value;
    setSelectedStudents((old)=>([...newStudents]));
    updateSelectedStudents(bucket, newStudents);
    
    
}

const handleSelectAllStudents = (event) =>{

}

function getStudentSelectTitle(studentid){
  let foundIndex = students.findIndex(s=>s._id===studentid);
  if(foundIndex>-1)
      return students[foundIndex].name;
  else
      return 'Not Found'
}
return (
  <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="studentsLabel">Students</InputLabel>
            <Select
            labelId="studentsLabel"
            label="Students"
            id="studentsMultiple"
            multiple
            value={selectedStudents}
            onChange={handleStudentsSelectChange}
            renderValue={(selected) => selected.map(studentid=>getStudentSelectTitle(studentid)).join(", ")}
            MenuProps={MenuProps}
            >
              <MenuItem value="all">
                {<Checkbox checked={selectedStudents.length===students.length} 
                  indeterminate={selectedStudents.length > 0 && selectedStudents.length < students.length}
                />}
                <ListItemText primary={'Select All'} />
              </MenuItem>
            {students.map((student, key) => (
                <MenuItem key={student._id} value={student._id}>
                    {<Checkbox checked={selectedStudents.findIndex(s=>s===student._id) > -1} />}
                    <ListItemText primary={student.name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
      </Grid>
      
  </Grid>
)
}

const AssignmentBucketsView = (props) => {
  const classes = useStyles();
  const section = props.section;
  const propsUser = props.propsUser;
  const propsUpdateUser = props.propsUpdateUser;

  const assignment = props.selectedAssignment;
  const assignmentBucketsVisible = props.assignmentBucketsVisible;
  const setAssignmentBucketsVisible = props.setAssignmentBucketsVisible;

  const [buckets, setBuckets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [bucket, setBucket] = React.useState({});

  const [refresh, setRefresh] = React.useState(new Date());
  
  //error handling states
  const [error, setError] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  
  const isMounted = React.useRef(null);

  const handleSaveBucket = (e) => {
    console.log(propsUser);
    setBucket(bucket=>({...bucket, validate:true, validateCallBack: (validBucket)=>{
      if(!validBucket){
        setError('Please fix the required information before proceeding.');
        setBucket(bucket=>({...bucket, validateCallBack: null}));
      }else{
        _saveBucket();
        setBucket(bucket=>({...bucket, validateCallBack: null}));
      }
    }}));
  }

  const _saveBucket = (existingBucket) => {
    console.log('existing', existingBucket);
    existingBucket&&delete existingBucket.attachments;
    setLoading(true);
      const bucketToSave = existingBucket?existingBucket:bucket;
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/"+assignment._id+"/buckets", 
      {bucket: {...bucketToSave}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        setLoading(false);
        !existingBucket&&setRefresh(new Date());
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                //setNewAssignmentVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost");
            }                    
        }
      });
    
    
  }

  function updateExistingBucket(bucket){
    let bucketIndex = buckets.findIndex(b=>b._id===bucket._id);
    let tempBuckets = [...buckets];
    tempBuckets[bucketIndex] = bucket;
    setBuckets(tempBuckets);
  }

  const handleTextChange = (e, fieldName) => {
      setBucket({...bucket, [fieldName]: e.target.value.trim()});
  }

  const handleBucketNameBlur = (e) =>{
    let newValue = e.target.value;
    let bucketid = e.target.getAttribute("data-bucketid");
    if(newValue!==e.target.getAttribute("data-init")){
    if(newValue.length < 5){
      e.target.value = e.target.getAttribute("data-init");
      return;
    }
    const existingBucketIndex = buckets.findIndex(b=>b._id===bucketid);
    let tempBucket = {...buckets[existingBucketIndex]};
    tempBucket.name = newValue;
    _saveBucket(tempBucket);
    }
  }

  const handleBucketNameFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }

  const updateSelectedStudents = (bucket, selectedStudents) =>{
    let tempBucketIndex = buckets.findIndex(b=>b._id===bucket._id);
    let tempBucket = {...buckets[tempBucketIndex]};
    tempBucket.students = selectedStudents;
    //don't include the name change
    tempBucket.name&&delete tempBucket.name;
    _saveBucket(tempBucket);
  }
 

  React.useEffect(()=>{
    let validatedBucket = bucket;
    
    const validateFields = () => {
      let bucketValid = true;
      
      if(!validatedBucket.name){
        bucketValid = false;
        setErrors(errors=> ({...errors, 'name': true}));
        setErrorMessages(errorMessages => ({...errorMessages, 'name': 'Required!'}));
      }else if(validatedBucket.name.length < 5){
        bucketValid = false;
        setErrors(errors=> ({...errors, 'name': true}));
        setErrorMessages(errorMessages=> ({...errorMessages, 'name': 'Minimum of 5 characters is required'}));
      }else{
        cancelErrors('name');
      }

      if(validatedBucket.validateCallBack)
        validatedBucket.validateCallBack(bucketValid);
    
    }

    const cancelErrors = (fieldName) => {
      setErrors(errors=>({...errors, [fieldName]: false}));
      setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
    }

    if(validatedBucket.validate){
      validateFields();
    }
      
  },[bucket]);

  React.useEffect(()=>{
    isMounted.current = true;
    
    const loadAssignmentBuckets = () => {
      setLoading(true);
      axios.get(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/"+assignment._id+"/buckets",{
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        setBuckets(res.data);
        setLoading(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
    };

    //load existing section
    if(refresh)
      loadAssignmentBuckets();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  },[refresh, assignment, section, propsUser, propsUpdateUser]); 

  React.useEffect(()=>{
    setTimeout(()=>{
      if(error !== ''){
        setError('');
      }
    }, 3000);
  },[error]);

  return (
    <Fragment>
        {(error !== '') &&
          <Alert style={{marginBottom: 10}} severity='error'>
              {error}
          </Alert>
        }

        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => props.setAssignmentBucketsVisible(false)}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography 
            component="h2" 
            variant="h5" 
            color="primary" 
            gutterBottom>
              Assignment Buckets - {assignment.name} - {assignment.points} Points
            </Typography>
          </Grid>
        </Grid>
        
          <Grid container spacing={3} alignItems="flex-start" justify='center'>

            <Grid item xs={6}>  
                <TextField 
                  required
                  error={errors['name']? true: false}
                  helperText={errorMessages['name']? errorMessages['name']: null}
                  defaultValue= {""}
                  onChange={(e) => handleTextChange(e, 'name')}
                  label="Bucket Name" 
                  variant="outlined" 
                  fullWidth
                  autoFocus>
                </TextField>
            </Grid>
            <Grid item xs={3}>  
                <IconButton onClick={(e)=>handleSaveBucket()}>
                  <AddIcon />
                </IconButton>
            </Grid>
            <Grid item xs={3}> 
            {loading&&
              <CircularProgress  />
            } 
            </Grid>
            
          </Grid>
          
         
          
          <Typography 
            component="h2" 
            variant="h6" 
            style={{marginTop: 20}}
            /* color="primary" */ 
            gutterBottom>
              {buckets&&buckets.length || 0} Buckets in this Assignment
          </Typography> 
          
          <Grid container spacing={2}>
          
            {buckets?.map((bucket, key)=>(
            <Grid item xs={6} key={key}>  
                <Card className={classes.cardRoot}>
                    <CardHeader style={{backgroundColor: bucket.bgcolor?bucket.bgcolor:"#ffffff"}}
                      avatar={
                        <Avatar aria-label="Section" className={classes.avatar}>
                          <ListAltSharpIcon />
                        </Avatar>
                      }
                      title={
                        <InputBase
                          defaultValue={bucket.name}
                          inputProps={{ 
                            'aria-label': 'naked',
                            'data-bucketid': bucket._id
                          }}
                          onBlur={(e)=>handleBucketNameBlur(e)}
                          onFocus={(e)=>handleBucketNameFocus(e)}
                        />
                      }
                      subheader={bucket.attachments.length + " Documents"}
                    />
                    <CardContent>
                      <BucketStudentsView students={section.students} bucket={bucket} updateSelectedStudents={updateSelectedStudents} />
                      <BucketDocumentsView 
                      bucket={bucket} updateExistingBucket={updateExistingBucket} section={section} assignment={assignment} setError={setError} 
                      assignmentBucketsVisible={assignmentBucketsVisible} propsUser={propsUser} />
                    </CardContent>
                </Card>
            </Grid>
            ))}
            
          </Grid>
    </Fragment>
    )
};
export default AssignmentBucketsView;
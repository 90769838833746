import axios from 'axios'
import edusim from '../config/edusim';

export const Api = {
    getTermsLevels: (user) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(edusim.api_base_uri+"/api/instructors/termslevels",{
                headers: {
                  'x-access-token': user.token
                }
                }).then(res => {
                  console.log("terms levels res", res);
                  resolve(res.data);
                }).catch(e => {
                  reject(e);
                });
        })
    },
    getMySections: (user, _termid) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(edusim.api_base_uri+"/api/instructors/getMySections/"+_termid,{
                headers: {
                'x-access-token': user.token
                }
            }).then(res => {
                console.log("sections res", res);
                resolve(res.data);
            }).catch(e => {
                reject(e);
            });
        });
    },
    getMyDashboard: (user, _termid) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(edusim.api_base_uri+"/api/instructors/getMyDashboard/"+_termid,{
                headers: {
                'x-access-token': user.token
                }
            }).then(res => {
                console.log("dashboard res", res);
                resolve(res.data);
            }).catch(e => {
                reject(e);
            });
        });
    }
}
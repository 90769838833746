import React, { Fragment } from 'react';
import { Divider, Card, Icon, Box, Typography } from '@material-ui/core';

import { VictoryChart, VictoryGroup, VictoryArea, VictoryBar, VictoryAxis, VictoryTheme, VictoryLabel } from 'victory';

export default function LineGraphCard(props){
    const getSectionAveragesData = props.getSectionAveragesData;
    function noData(){
      console.log(getSectionAveragesData());
      if(getSectionAveragesData().length===0) return true;
      
      let noData = true;
      getSectionAveragesData().map((d,key)=>{
        if(d.y>0)
          noData=false;
      });
      return noData;
    }
    return(

      <Card style={{overflow: 'visible', paddingLeft: 5}}>
        <Typography variant="h6">Achievement By Section</Typography>
        {noData()?
        <Typography>Not enough data to display chart</Typography>
        :
       <VictoryChart style={{marginLeft: 10}} domainPadding={15} domain={{ y: [0, 100] }}>
        
          {/* <VictoryAxis
            dependentAxis={true}
            style={{
              grid: { stroke: "grey" }
            }}
          />
          <VictoryAxis 
            //tickFormat={(x) => ``}
            style={{ tickLabels: {angle: 0, fontSize: 6}}}
            tickLabelComponent={<VictoryLabel y={260} verticalAnchor={"start"}/>}
          /> */}

          <VictoryAxis
            dependentAxis={true}
            style={{
              grid: { stroke: "grey" }
            }}
          />
          <VictoryAxis 
            //tickFormat={(x) => ``}
            style={{ tickLabels: {angle: 0, fontSize: 8}}}
            tickLabelComponent={<VictoryLabel x={0} textAnchor={"start"} verticalAnchor={"middle"}/>}
          />
            <VictoryGroup 
              horizontal
              //offset={10}
              //style={{ data: { width: 6 } }}
              colorScale={["brown", "tomato", "gold"]}
            >
             
               <VictoryBar
                    barWidth={20}
                    width={600}
                    //style={{ data: { fill: "red" }}}
                    data={getSectionAveragesData()}
                  />
                 

            </VictoryGroup>
          </VictoryChart>
          }
      </Card>
    )
}
import React from 'react';
import MainApp from './views/MainAppView';
import './App.css';
import {useState} from 'react';
import SignInView from "./views/SignInSide";

function App() {

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('@user')) || {}
  );

  const updateUserInformation = (user) =>{
    setUser(user);
    localStorage.setItem("@user", JSON.stringify(user));
  }

  React.useEffect(() => {
  },[]);

  return (
    <div>

    {!user.token &&
      <SignInView updateUserInformation={updateUserInformation} />}

    {user.token &&
      <MainApp updateUserInformation={updateUserInformation} user={user} />}
      
    </div>
  );
}

export default App;
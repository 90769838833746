import React from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    Button,
    CircularProgress,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Tooltip,
    makeStyles,
    Box,
    Fab
} from '@material-ui/core';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon, 
    Add as AddIcon    
} from '@material-ui/icons';

import FloatingActionButton from './FAB';

import AssignmentGradeEntry from '../views/AssignmentGradeEntry';
import AssignmentView from '../views/AssignmentView';
import axios from 'axios';
import edusim from '../config/edusim';
import SubjectProgReportView from './SubjectProgReportView';

const SubjectProgressView = (props) => {
    const [newReportVisible, setNewReportVisible] = React.useState(false);
    const [editReportVisible, setEditReportVisible] = React.useState(false);
    const [selectedYear, setSelectedYear] = React.useState(2021);
    const [selectedTerm, setSelectedTerm] = React.useState(null);
    const [selectedGi, setSelectedGi] = React.useState(null);
    
    const [selectedReport, setSelectedReport] = React.useState(null);
    
    const subject = props.subject;
    const objectives = props.objectives;
    const progressReports = props.progressReports;
    const loadingSub = props.loadingSub;
    const yearsObject = props.yearsObject;
    
    //const [currentReports, setCurrentReports] = React.useState(progressReports);

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const isMounted = React.useRef(null);
  
    const [loading, setLoading] = React.useState(false);

    //error handling states
    const [error, setError] = React.useState('');

    function progressReportsInGradingInterval(_intervalid){
        return progressReports.filter((r)=>r._gradingintervalid===_intervalid);
    }

    function getGradingIntervalsInTerm(_termid){
        const gisInTerm = subject._levelid.grading_intervals.filter((gi)=>gi._termid===_termid);
        return gisInTerm;
    }

    const handleYearChange = (e) =>{
        let year = e.target.value;
        setSelectedYear(year);
    }

    const handleAddReportClick = (e, term, gi) =>{
        setSelectedGi(gi);
        setSelectedTerm(term);
        setNewReportVisible(true);
    }

    const handleReportClick = (e, term, gi, report) =>{
        setSelectedGi(gi);
        setSelectedTerm(term);
        setSelectedReport(report);
    }

    return (
        <React.Fragment>
            {newReportVisible?
                <SubjectProgReportView 
                    propsUser={propsUser}
                    propsUpdateUser={propsUpdateUser}
                    subject={subject}
                    selectedGi={selectedGi}
                    selectedTerm={selectedTerm}
                    selectedYear={selectedYear}
                    setNewReportVisible={setNewReportVisible}
                    objectives={props.objectives}
                    schoolObjectives={props.schoolObjectives}
                    schoolObjectivesRatings={props.schoolObjectivesRatings}
                    refreshProgressReports={props.refreshProgressReports}
                />
            :
            selectedReport?
                <SubjectProgReportView 
                    propsUser={propsUser}
                    propsUpdateUser={propsUpdateUser}
                    subject={subject}
                    selectedGi={selectedGi}
                    selectedTerm={selectedTerm}
                    selectedYear={selectedYear}
                    setSelectedReport={setSelectedReport}
                    selectedReport={selectedReport}
                    objectives={props.objectives}
                    schoolObjectives={props.schoolObjectives}
                    schoolObjectivesRatings={props.schoolObjectivesRatings}
                    refreshProgressReports={props.refreshProgressReports}
                />
            :
            <React.Fragment>
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item>
                        <Typography component="h2" variant="h6" color="primary" >Progress Reports {selectedYear}</Typography>
                    </Grid>
                    <Grid item>
                        <Select
                        variant='outlined'
                        defaultValue={2021}
                        onChange={(e) => handleYearChange(e)}>
                        {yearsObject&&Object.keys(yearsObject).map((key, index)=>(
                            <MenuItem key={index} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                        </Select>
                    </Grid>
                </Grid>
            
           
            {yearsObject[selectedYear]?.map((term, kt)=>(
                <Box key={kt} style={{marginTop:20}}>
                    <Typography component="h2" variant="h6" color="default">{term.name}</Typography>
                    {getGradingIntervalsInTerm(term._id).map((gi, kgi)=>(
                        <Box key={kgi} style={{marginTop:10}}>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Typography>{gi.name} ({gi.short_name}) Reports</Typography>
                                </Grid>
                                <Grid item style={{marginLeft: 10}}>
                                    <Fab onClick={(e)=>handleAddReportClick(e, term, gi)} color="primary" aria-label="add" size ="small"> 
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                            {progressReportsInGradingInterval(gi._id).map((report, key)=>(
                                <Button key={key} onClick={(e)=>handleReportClick(e,term, gi, report)}>{report.name}</Button>
                            ))}
                        </Box>
                    ))}
                </Box>
            ))}
            </React.Fragment>
        }
        </React.Fragment>
        
    )
};
export default SubjectProgressView;
import React, { useCallback, useState } from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    Button,
    CircularProgress,
    TableHead,
    TableCell,
    TableRow,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableBody,
    Table,
    Tooltip,
    makeStyles,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Box,
    Fab
} from '@material-ui/core';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon, 
    Add as AddIcon,
    ExpandMore as ExpandMoreIcon,    
    ReorderTwoTone
} from '@material-ui/icons';
import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import { sub } from 'date-fns';
import axios from 'axios';
import edusim from '../config/edusim';

const useStyles = makeStyles((theme) => ({ 
    domainCell: {
      fontSize: 20,
      paddingLeft: theme.spacing(1),
      width: '70%'
    },
    domainInfoCell: {
        fontSize: 20,
        textAlign:'right'
    },
    clusterCell: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        width: '70%'
    },
    clusterInfoCell: {
        fontSize: 18,
        textAlign:'right'
    },
    standardCell: {
        paddingLeft: theme.spacing(4),
        width: '70%'
    },
    standardRatingCell: {
        textAlign: 'right'
    },
    substandardCell: {
        paddingLeft: theme.spacing(6),
        width: '70%'
    },
    substandardRatingCell: {
        textAlign: 'right'
    },
    schoolObjectiveCell: {
        fontSize: 16,
        paddingLeft: theme.spacing(2),
        width: '60%'
    },
    schoolObjectiveRatingCell: {
        textAlign:'right'
    },
    
}));

function getPercent(ratio){
    return (Math.round(((ratio*100) + Number.EPSILON) * 100) / 100);
}

function formatPercent(percent){
return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
}

function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
}

const StudentBadge = React.memo((props) =>{
    const studentid = props.studentid;
    const students = props.students;
    const studentindex = students.findIndex(s=>s._id===studentid);
    const reportName = props.reportName;
    const reportTerm = props.reportTerm;
    const reportInterval = props.reportInterval;
    const getTotalStandardsCount = props.getTotalStandardsCount;
    const getStudentRatedCount = props.getStudentRatedCount;

    const getTotalSchoolObjectivesCount = props.getTotalSchoolObjectivesCount;
    const getStudentRatedSOCount = props.getStudentRatedSOCount;

    return (
        <Grid container spacing={3} alignItems='center' justify='center'>
            <Grid item xs>
            <List style={{width:'100%', minWidth:300}} key={props.key}>
                <ListItem>
                <ListItemAvatar>
                <Avatar
                    alt={students[studentindex].name}
                    src={maleAvatarPlaceholder}
                />
                </ListItemAvatar>
                <ListItemText primary={students[studentindex].name} secondary={reportName + " - " + reportTerm + " - " + reportInterval} />
                
            </ListItem>
            </List>
            </Grid>
            <Grid item xs>
                <Typography>Rated {getStudentRatedCount(studentid) + " of " + getTotalStandardsCount()} Subject Standards</Typography>
                <Typography>Rated {getStudentRatedSOCount(studentid) + " of " + getTotalSchoolObjectivesCount()} School Objectives</Typography>
            </Grid>
        </Grid>
        
    )
});

const StudentRatingComponent = (props) =>{
    const [currentValue, setCurrentValue] = useState(props.defaultValue);
    const student = props.student;
    const domain = props.domain;
    const cluster = props.cluster;
    const standard = props.standard;
    const substandard = props.substandard;
    const setMainValue = props.setMainValue;

    const onLocalChange = (e)=>{
        setCurrentValue(e.target.value);
        setMainValue(parseInt(e.target.value), student, domain, cluster, standard, substandard);
    }

    const groupComponent = React.useMemo(()=>{
        return(
            <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={currentValue}
                onChange={(e)=>onLocalChange(e)}
                >
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="1" control={<Radio />} label="1" />
                
            </RadioGroup>
            </FormControl>
            )
    }, [currentValue, student, domain, cluster, standard]);

    return groupComponent;
};

const StudentSchoolRatingComponent = (props) =>{
    const [currentValue, setCurrentValue] = useState(props.defaultValue);
    const student = props.student;
    const schoolObjective = props.schoolObjective;
    const schoolObjectivesRating = props.schoolObjectivesRating;
    const setMainValue = props.setMainValue;

    const onLocalChange = (e)=>{
        setCurrentValue(e.target.value);
        setMainValue(e.target.value, student, schoolObjective);
    }

    const groupComponent = React.useMemo(()=>{
        return(
            <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={currentValue}
                onChange={(e)=>onLocalChange(e)}
                >
               {schoolObjectivesRating?.map((rating, key)=>{
                    return(
                        <FormControlLabel key={key} value={rating} control={<Radio />} label={rating} />
                    )
                })}
                
            </RadioGroup>
            </FormControl>
            )
    }, [currentValue, student]);

    return groupComponent;
};

const SectionProgReportView = (props) => {
    const classes=useStyles();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const [ratingObjectId, setRatingObjectId] = React.useState(null);
    
    const studentObjectiveRating = React.useRef({});
    
    const schoolObjectives = props.schoolObjectives;
    const schoolObjectivesRating = props.schoolObjectivesRating;
    const studentSchoolObjectiveRating = React.useRef({});

    const selectedReport = props.selectedReport;
    const intervalName = props.intervalName;
    const students = props.students;
    const sectionId = props.sectionId;

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const selectedObjectives = getSelectedObjectives(selectedReport);

    function getSelectedObjectives(report){
        const selectedObjectives = JSON.parse(report.selectedObjectives.jsonObject);
        return selectedObjectives;
    }

    function domainHasSelectedClusters(domain){
        let hasClusters = false;
        const domainKeys= Object.keys(selectedObjectives[domain]);
        domainKeys.forEach((cluster)=>{
            const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
            clusterKeys.forEach((standard)=>{
                standard!=='selected'&&selectedObjectives[domain][cluster][standard].selected&&(hasClusters=true);
            });
        });
        return hasClusters;
    }

    function clusterHasSelectedStandards(domain, cluster){
        let hasStandards = false;
        const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
        clusterKeys.forEach((standard)=>{
            standard!=='selected'&&selectedObjectives[domain][cluster][standard].selected&&(hasStandards=true);
        });
        return hasStandards;
    }

    const setMainValue = (value, student, domain, cluster, standard, substandard) =>{
        ///console.log(value, student, domain, cluster, standard );
        //console.log(studentObjectiveRating.current);
        let tempObject = {...studentObjectiveRating.current};
        //console.log(tempObject);
        !tempObject[student._id]&&(tempObject[student._id]={});
        !tempObject[student._id][domain]&&(tempObject[student._id][domain]={});
        !tempObject[student._id][domain][cluster]&&(tempObject[student._id][domain][cluster]={});        
        !substandard&&(tempObject[student._id][domain][cluster][standard] = parseInt(value));
        //console.log(tempObject);
        if(substandard){
            !tempObject[student._id][domain][cluster][standard]&&(tempObject[student._id][domain][cluster][standard]={});
            tempObject[student._id][domain][cluster][standard][substandard] = parseInt(value);
        }
        studentObjectiveRating.current = tempObject;
    }

    function getAverageRating(student){
        let studentRatingObject = {...studentObjectiveRating[student._id]};
        
        //console.log("rating object", studentRatingObject);
    }

    function getStudentDefaultValue(studentId, domain, cluster, standard, substandard){
        let dv = studentObjectiveRating.current[studentId]
        &&studentObjectiveRating.current[studentId][domain]
        &&studentObjectiveRating.current[studentId][domain][cluster]&&
        studentObjectiveRating.current[studentId][domain][cluster][standard]?studentObjectiveRating.current[studentId][domain][cluster][standard]:null;
        

        if(substandard){
            dv = studentObjectiveRating.current[studentId]
            &&studentObjectiveRating.current[studentId][domain]
            &&studentObjectiveRating.current[studentId][domain][cluster]&&
            studentObjectiveRating.current[studentId][domain][cluster][standard]&&
            studentObjectiveRating.current[studentId][domain][cluster][standard][substandard]?studentObjectiveRating.current[studentId][domain][cluster][standard][substandard]:null;
        }

        return dv?dv.toString():'';
    }

    function getTotalStandardsCount(){
        let objectivesTotal = 0;
        const objsKeys = Object.keys(selectedObjectives);
        objsKeys.forEach((domain)=>{
            const domainKeys= Object.keys(selectedObjectives[domain]);
            domainKeys.forEach((cluster)=>{
                const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
                clusterKeys.forEach((standard)=>{
                    standard!=='selected'&&selectedObjectives[domain][cluster][standard].selected&&(objectivesTotal++);
                    const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
                    standardKeys.forEach((substandard)=>{
                        substandard!=='selected'&&selectedObjectives[domain][cluster][standard][substandard].selected&&(objectivesTotal++);
                    });
                });
            });
        });
        
        return objectivesTotal;
    }

    function getStudentRatedCount(studentid){
        let studentObject = studentObjectiveRating.current[studentid]?studentObjectiveRating.current[studentid]:null;
        if(!studentObject) return 0;
        let ratedTotal = 0;
        const objsKeys = Object.keys(selectedObjectives);
        objsKeys.forEach((domain)=>{
            const domainKeys= Object.keys(selectedObjectives[domain]);
            domainKeys.forEach((cluster)=>{
                const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
                clusterKeys.forEach((standard)=>{
                    const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
                    studentObject[domain]&&
                    studentObject[domain][cluster]&&
                    studentObject[domain][cluster][standard]&&(ratedTotal++);
                    standardKeys.forEach((substandard)=>{
                        studentObject[domain]&&
                        studentObject[domain][cluster]&&
                        studentObject[domain][cluster][standard]&&
                        studentObject[domain][cluster][standard][substandard]&&(ratedTotal++);
                    });
                });
            });
        });
        return ratedTotal;
    }

    function getStudentRatedCountInDomain(studentid, domain){
        let studentObject = studentObjectiveRating.current[studentid]?studentObjectiveRating.current[studentid]:null;
        if(!studentObject || !studentObject[domain]) return 0;
        let ratedTotal = 0;
        const domainKeys= Object.keys(selectedObjectives[domain]);
        domainKeys.forEach((cluster)=>{
            const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
            clusterKeys.forEach((standard)=>{
                const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
                studentObject[domain]&&
                studentObject[domain][cluster]&&
                studentObject[domain][cluster][standard]&&(ratedTotal++);
                standardKeys.length>1&&(ratedTotal--);
                standardKeys.forEach((substandard)=>{
                    studentObject[domain]&&
                    studentObject[domain][cluster]&&
                    studentObject[domain][cluster][standard]&&
                    studentObject[domain][cluster][standard][substandard]&&(ratedTotal++);
                });
            });
        });
        return ratedTotal;
    }

    function getStudentTotalPointsInDomain(studentid, domain){
        let studentObject = studentObjectiveRating.current[studentid]?studentObjectiveRating.current[studentid]:null;
        if(!studentObject || !studentObject[domain]) return 0;
        let pointsTotal = 0;
        const domainKeys= Object.keys(selectedObjectives[domain]);
        domainKeys.forEach((cluster)=>{
            const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
            clusterKeys.forEach((standard)=>{
                const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
                standardKeys.length===1&&studentObject[domain]&&
                studentObject[domain][cluster]&&
                studentObject[domain][cluster][standard]&&(pointsTotal=pointsTotal+studentObject[domain][cluster][standard]);
                standardKeys.forEach((substandard)=>{
                    studentObject[domain]&&
                    studentObject[domain][cluster]&&
                    studentObject[domain][cluster][standard]&&
                    studentObject[domain][cluster][standard][substandard]&&(pointsTotal=pointsTotal+studentObject[domain][cluster][standard][substandard]);
                });
            });
        });
        return pointsTotal;
    }

    function getStudentRatedCountInCluster(studentid, domain, cluster){
        let studentObject = studentObjectiveRating.current[studentid]?studentObjectiveRating.current[studentid]:null;
        if(!studentObject || !studentObject[domain] || !studentObject[domain][cluster]) return 0;
        let ratedTotal = 0;
        const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
        clusterKeys.forEach((standard)=>{
            const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
            studentObject[domain]&&
            studentObject[domain][cluster]&&
            studentObject[domain][cluster][standard]&&(ratedTotal++);
            standardKeys.length>1&&(ratedTotal--);
            standardKeys.forEach((substandard)=>{
                studentObject[domain]&&
                studentObject[domain][cluster]&&
                studentObject[domain][cluster][standard]&&
                studentObject[domain][cluster][standard][substandard]&&(ratedTotal++);
            });
        });
        return ratedTotal;
    }

    function getStudentTotalPointsInCluster(studentid, domain, cluster){
        let studentObject = studentObjectiveRating.current[studentid]?studentObjectiveRating.current[studentid]:null;
        if(!studentObject || !studentObject[domain] || !studentObject[domain][cluster]) return 0;
        let pointsTotal = 0;
        const clusterKeys= Object.keys(selectedObjectives[domain][cluster]);
        clusterKeys.forEach((standard)=>{
            const standardKeys= Object.keys(selectedObjectives[domain][cluster][standard]);
            standardKeys.length===1&&studentObject[domain]&&
            studentObject[domain][cluster]&&
            studentObject[domain][cluster][standard]&&(pointsTotal=pointsTotal+studentObject[domain][cluster][standard]);
            standardKeys.forEach((substandard)=>{
                studentObject[domain]&&
                studentObject[domain][cluster]&&
                studentObject[domain][cluster][standard]&&
                studentObject[domain][cluster][standard][substandard]&&(pointsTotal=pointsTotal+studentObject[domain][cluster][standard][substandard]);
            });
        });
        return pointsTotal;
    }

    /**School Objectives Functions */
    const studentSchoolObjectiveRatingChange = (value, student, rating) =>{
        let tempObject = {...studentSchoolObjectiveRating.current};
        !tempObject[student._id]&&(tempObject[student._id]={});
        tempObject[student._id][rating] = value;
        studentSchoolObjectiveRating.current = tempObject;
    }


    function getStudentSchoolObjectiveRating(studentid, rating) {
        let val = (studentSchoolObjectiveRating.current[studentid]
                    &&studentSchoolObjectiveRating.current[studentid][rating])?studentSchoolObjectiveRating.current[studentid][rating]:'';
        return val;
    }

    function getTotalSchoolObjectivesCount(){
        return schoolObjectives.length;
    }

    function getStudentRatedSOCount(studentid){
        let studentObject = studentSchoolObjectiveRating.current[studentid]?studentSchoolObjectiveRating.current[studentid]:null;
        if(!studentObject) return 0;
        return Object.keys(studentObject).length;
    }

    const isMounted = React.useRef(null);  

     React.useEffect(()=>{

        isMounted.current = true;
        
        const loadSectionRating = () => {
          setLoading(true);
          axios.get(edusim.api_base_uri+"/api/instructors/sections/"+sectionId+"/progressReports/"+selectedReport._id,{
            headers: {
              'x-access-token': propsUser.token
            }
          }).then(res => {
            const ratingObj = res.data;
            ratingObj._id&&setRatingObjectId(ratingObj._id);
            res.data.ratings?.jsonObject&&(studentObjectiveRating.current=JSON.parse(res.data.ratings.jsonObject));
            res.data.schoolRatings?.jsonObject&&(studentSchoolObjectiveRating.current=JSON.parse(res.data.schoolRatings.jsonObject));
            setLoading(false);
          }).catch(e => {
            if(e.response){
                if(isMounted.current){
                    setLoading(false);
                }
                if(e.response.status === 403){
                    propsUpdateUser({});
                }
            }else{
                if(isMounted.current){
                    setLoading(false);
                    setError("Network connection might be lost, ")
                }                    
            }
          });
        };
    
        if(sectionId)
            loadSectionRating();
    
        return () => {
          // executed when unmount
          isMounted.current = false;
        }
    }, [sectionId, selectedReport, propsUpdateUser, propsUser]);

    const _saveReport = () => {
        let ratingToSave = {};
        ratingObjectId&&(ratingToSave._id=ratingObjectId);
        ratingToSave._reportid = selectedReport._id;
        ratingToSave._sectionid = sectionId;
        ratingToSave.ratings = {};
        ratingToSave.ratings.jsonObject = JSON.stringify(studentObjectiveRating.current);
        ratingToSave.schoolRatings = {};
        ratingToSave.schoolRatings.jsonObject = JSON.stringify(studentSchoolObjectiveRating.current);
        setLoading(true);
        axios.post(edusim.api_base_uri+"/api/instructors/sections/"+sectionId+"/progressReports/"+selectedReport._id, 
        {state: ratingToSave}, {
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          setLoading(false);
          //props.setSelectedReport(null);
        }).catch(e => {
          if(e.response){
              if(e.response.status === 403){
                  propsUpdateUser({});
              }else{
                  setError("Cannot add progress report at this moment.");
              }
          }else{
              setError("Network connection might be lost");         
          }
        }).finally(()=>{
            setLoading(false);
        });
      
    }

    return (
        <React.Fragment>

            {loading?
            <Grid container spacing={3} style={{marginBottom: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
                
            </Grid>
            :
            <Grid container spacing={3} style={{marginBottom: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button onClick={(e)=>props.setSelectedReport(null)}>
                  Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" color="primary" onClick={(e)=>_saveReport()}> 
                    Save Report
                  </Button>
                </Grid>
            </Grid>
            }

            {students&&students.map((obj, key) => (
            <Accordion key={key} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StudentBadge 
                    students={students} 
                    studentid={obj._id} 
                    reportName={selectedReport.name} 
                    reportTerm={selectedReport._termid.name + " " + selectedReport._termid.year} 
                    reportInterval={intervalName}
                    getTotalStandardsCount={getTotalStandardsCount}
                    getStudentRatedCount={getStudentRatedCount} 
                    getTotalSchoolObjectivesCount={getTotalSchoolObjectivesCount}
                    getStudentRatedSOCount={getStudentRatedSOCount}
                    />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                
                <Grid item xs={12}>
                <Typography component="h2" variant="h5" color="primary" >Subject Standards</Typography>
                <Table>
                    <TableBody>
                        {selectedObjectives&&Object.entries(selectedObjectives).map(([domain, clusters])=>{
                            return(
                                domainHasSelectedClusters(domain)&&
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell className={classes.domainCell}>{domain}</TableCell>
                                        <TableCell className={classes.domainInfoCell}>
                                            Domain Rating: {roundAmount(getStudentTotalPointsInDomain(obj._id, domain) / getStudentRatedCountInDomain(obj._id, domain))}
                                        </TableCell>
                                    </TableRow>
                                    {Object.entries(clusters).map(([cluster, standards])=>{
                                        return(
                                            cluster!=='selected'&&clusterHasSelectedStandards(domain, cluster)&&
                                            <React.Fragment>
                                            <TableRow>
                                                <TableCell className={classes.clusterCell}>{cluster}</TableCell>
                                                <TableCell className={classes.clusterInfoCell}>
                                                    Cluster Rating: {roundAmount(getStudentTotalPointsInCluster(obj._id, domain, cluster) / getStudentRatedCountInCluster(obj._id, domain, cluster))}
                                                </TableCell>
                                            </TableRow>
                                            {Object.entries(standards).map(([standard, substandards])=>{
                                                return (
                                                    standard!=='selected'&&standards[standard].selected&&
                                                    <React.Fragment>
                                                    <TableRow>
                                                        <TableCell className={classes.standardCell}>{standard}</TableCell>
                                                        <TableCell className={classes.standardRatingCell}>
                                                        {Object.keys(substandards).length===1&&
                                                            <StudentRatingComponent 
                                                            defaultValue={(e)=>getStudentDefaultValue(obj._id, domain, cluster, standard)} 
                                                            setMainValue={setMainValue}
                                                            student={obj} domain={domain} cluster={cluster} standard={standard} 
                                                            />
                                                        }
                                                        </TableCell>
                                                    </TableRow>
                                                    {Object.entries(substandards).map(([substandard, val])=>{
                                                        return (
                                                            substandard!=='selected'&&substandards[substandard].selected&&
                                                            <React.Fragment>
                                                            <TableRow>
                                                                <TableCell className={classes.substandardCell}>{substandard}</TableCell>
                                                                <TableCell className={classes.substandardRatingCell}>
                                                                <StudentRatingComponent 
                                                                    defaultValue={(e)=>getStudentDefaultValue(obj._id, domain, cluster, standard, substandard)} 
                                                                    setMainValue={setMainValue}
                                                                    student={obj} domain={domain} cluster={cluster} standard={standard} substandard={substandard}
                                                                />
                                                                </TableCell>
                                                            </TableRow>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    </React.Fragment>
                                                )
                                            })}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
                </Grid>

                <Grid item xs={12}>
                <Typography component="h2" variant="h5" color="primary" >School Level Objectives</Typography>
                <Table>
                    <TableBody>
                        {schoolObjectives?.map((schoolObjective, key)=>{
                            return (
                                <TableRow key={key}>
                                    <TableCell className={classes.schoolObjectiveCell}>{schoolObjective}</TableCell>
                                    <TableCell className={classes.schoolObjectiveRatingCell}>
                                        <StudentSchoolRatingComponent 
                                                student={obj}
                                                schoolObjective={schoolObjective}
                                                schoolObjectivesRating={schoolObjectivesRating}
                                                defaultValue={getStudentSchoolObjectiveRating(obj._id, schoolObjective)}
                                                setMainValue={studentSchoolObjectiveRatingChange}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                </Grid>
                
                </Grid>
            </AccordionDetails>
            </Accordion>
            
            ))}
        </React.Fragment>
    )
};

export default SectionProgReportView;